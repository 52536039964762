import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
// import { NavLink } from "react-router-dom";

//Components 
import NoRecords from "../../../components/NoRecords/NoRecords";

//Icons
// import { Icon } from "@iconify/react";

class BankingDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  }

  render() {
    // const { t } = this.props;
    // const truncate = (value) => value?.length > 12 ? `${value.substring(0, 12)}...` : value;

    const bankImg = this.imageImport(require.context("../../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));
    let data = this.props.memberBankData ? this.props.memberBankData : [];
    return (
      <>
        {!this.props.loading && data.length>0 &&
        <div className="pd-content">
          <div className="pi-bd-col">
            <ul className="list-group">
              {data.map((val,idx)=>{
                let bankColorImage = null;
                if (bankImg[`${val.swiftCode.toLowerCase()}-color.svg`] !== undefined) {
                  bankColorImage = bankImg[`${val.swiftCode.toLowerCase()}-color.svg`];
                } else {
                  bankColorImage = bankImg["default-bank-icon-color.png"];
                }
                return (
                  <div className="list-group-item" key={idx}>
                    <img
                      className="active"
                      src={bankColorImage}
                      alt="pbb-color"
                    />
                    <p>{val.memberBankAccNumber}</p>
                    {/* <Icon icon="uil:angle-right" /> */}
                  </div>
                )
              })}
            </ul>
          </div>
        </div>
        }
        {!this.props.loading && data.length===0 &&
          <NoRecords component="banking-details" />
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {}
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BankingDetails));
