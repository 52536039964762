import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import { isDesktop } from "react-device-detect";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
// import { Icon } from "@iconify/react";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import { Link } from "react-router-dom";


//Components 
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import MobileBonus from "./MobileBonus";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import BonusCodeModal from "../../../components/BonusModal/BonusCodeModal";
//Actions
import * as actions from "../../../store/ducks/bonus/actions";
// import * as vipActions from "../../../store/ducks/vip/actions";
import { claimVIPWeeklyBonus, claimVIPBirthdayBonus } from "../../../store/ducks/vip/actions";
import { claimAngpau, mobileAppsClaim } from "../../../store/ducks/angpau/actions";
// import { ValidationError } from "yup";

class Bonus extends Component
{
    constructor(props) 
    {
        super(props);
        this.state =
        {
            rescueBonus: {},
            delayBonus: {},
            loading: true,
            showBonusCodeModal: false,
            amount: "RM33",
            bonusCode: "",
            specialEventsData: [],
            longEventsData: [],
            seasonalEventsData: [],
            imageUrl: '',
            currentEvent:"",
        }
    }

    componentDidMount() 
    {
        this.props.funcGetList({});
        // Swal.fire({
        //     iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
        //     icon: "success",
        //     title: t("lucky-spin.congratulation"),
        //     text: t("bonus.have-won") + this.state.amount + "!" + t("bonus.have-wont-1"),
        //     confirmButtonText: t("global.ok"),
        //     customClass: (!isDesktop) ? {
        //         container: 'swal-mobile-container sucess',
        //         icon: 'swal-success-icon'
        //     } : []
        // });

        // Swal.fire({
        //     iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
        //     icon: 'error',
        //     title: t("global.sorry"),
        //     html: t('bonus.bonus-code'),
        //     showCancelButton: true,
        //     confirmButtonText: t("global.ok"),
        //     cancelButtonText: t("global.cancel"),
        //     customClass: (!isDesktop) ? {
        //         container: 'swal-mobile-container confirmation',
        //         icon: 'swal-sorry-icon'
        //     } : [],
        // });
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;

        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) 
        {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') 
            {
                let data = nextProps.rs.data;
                let rescueBonus = [];
                let delayBonus = [];
                let special_events_data = Object.values(data.special_events_data);
                let long_events_data = Object.values(data.long_events_data);
                let seasonal_events_data = Object.values(data.seasonal_events_data);
                let image_url = data.image_url;

                data.rescue_bonus.forEach((val) => 
                {
                    rescueBonus[val.game_type] = val;
                });

                data.delay_bonus.forEach((val) => 
                {
                    delayBonus[val.transaction_type] = val;
                });
                
                this.setState({
                    rescueBonus,
                    delayBonus,
                    loading: false,
                    specialEventsData: special_events_data,
                    longEventsData: long_events_data,
                    seasonalEventsData: seasonal_events_data,
                    imageUrl: image_url,
                });
            }
        }

        if (nextProps.rescue_bonus !== this.props.rescue_bonus && nextProps.rescue_bonus !== undefined) 
        {
            if (nextProps.rescue_bonus.responseCode === 200 && nextProps.rescue_bonus.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    text: t("vip.success-claim"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : []
                });
            }
        }

        if (nextProps.delay_bonus !== this.props.delay_bonus && nextProps.delay_bonus !== undefined) 
        {
            if (nextProps.delay_bonus.responseCode === 200 && nextProps.delay_bonus.msgType === "success") 
            {
                let data = {};
                data.currencyCode = this.props.currentUser.currency;

                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    text: t('bonus.delay-bonus-success-claim').replace("##amount##", `${data.currencyCode}${nextProps.delay_bonus.data.amount}`),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : []
                });
            }
        }

        if (nextProps.first_bet_bonus !== this.props.first_bet_bonus && nextProps.first_bet_bonus !== undefined) 
        {
            if (nextProps.first_bet_bonus.responseCode === 200 && nextProps.first_bet_bonus.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    text: t("vip.success-claim"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : []
                });
            } else {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "error",
                    title: t("global.error"),
                    text: nextProps.first_bet_bonus.message,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container failed',
                        icon: 'swal-failed-icon'
                    } : []
                });
            }
        }

        if (nextProps.voucher_code !== this.props.voucher_code && nextProps.voucher_code !== undefined) 
        {
            if (nextProps.voucher_code.responseCode === 200 && nextProps.voucher_code.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    text: t("vip.success-claim"),
                    confirmButtonText: t("global.ok"),
                    allowEnterKey: false,
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : []
                });

                this.toggleBonusCodeModal();

                this.setState({
                   bonusCode: "" 
                });
            } else {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "error",
                    title: t("global.error"),
                    text: !!(nextProps.voucher_code.message.input_error) ? nextProps.voucher_code.message.input_error.code : nextProps.voucher_code.message,
                    confirmButtonText: t("global.ok"),
                    allowEnterKey: false,
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container failed',
                        icon: 'swal-failed-icon'
                    } : []
                });
            }
        }

        if (nextProps.claim_vip_weekly_bonus !== undefined && nextProps.claim_vip_weekly_bonus !== this.props.claim_vip_weekly_bonus) 
        {
            if (nextProps.claim_vip_weekly_bonus.responseCode === 200 && nextProps.claim_vip_weekly_bonus.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    text: t("vip.success-claim"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    } : []
                });
            }
        }

        if (nextProps.claim_vip_birthday_bonus !== undefined && nextProps.claim_vip_birthday_bonus !== this.props.claim_vip_birthday_bonus) 
        {
            if (nextProps.claim_vip_birthday_bonus.responseCode === 200 && nextProps.claim_vip_birthday_bonus.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "info",
                    title: t("global.success"),
                    text: t("vip.success-claim"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                    container: 'swal-mobile-container sucess',
                    icon: 'swal-success-icon'
                    } : []
                });
            }
        }

        if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
            if (nextProps.claim_angpau_result.responseCode === 200) {
                if(nextProps.claim_angpau_result.data.depositStatus === false){
                  Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                    icon: "info",
                    title: t("global.sorry"),
                    text: this.state.currentEvent === "cny" ? t("cny.cny-requirement") : this.state.currentEvent === "raya" ? t("cny.raya-requirement") : this.state.currentEvent === "dewali" ? t("cny.dewali-requirement") : this.state.currentEvent === "thaipusam" && t("cny.thaipusam-requirement"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                      container: "swal-mobile-container sorry",
                      icon: "swal-sorry-icon",
                    } : []
                  });
                }else if(nextProps.claim_angpau_result.data.eligible === false) {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        icon: "error",
                        title: t("global.error"),
                        text: t("vip.not-allowed-to-claim-angbao"),
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                          container: "swal-mobile-container sorry",
                          icon: "swal-sorry-icon",
                        } : []
                      });
                }else{
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                        icon: "info",
                        title: t("global.success"),
                        text: t("vip.success-claim")+ " RM" + nextProps.claim_angpau_result.data.amount + " " + t("vip.angbao-to-your-wallet"),
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                        } : []
                    });
                }
            } else {
              return false;
            }
        }

        if (nextProps.mobile_apps_promo_claim !== this.props.mobile_apps_promo_claim) {
            const { t } = this.props;
            if (nextProps.mobile_apps_promo_claim.responseCode === 200) {
                if(nextProps.mobile_apps_promo_claim.data.eligible === false || nextProps.mobile_apps_promo_claim.data.depositStatus === false){
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        icon: "error",
                        title: t("global.error"),
                        text: t("vip.not-allowed-to-claim-mobile-apps-bonus"),
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                          container: "swal-mobile-container sorry",
                          icon: "swal-sorry-icon",
                        } : []
                      });
                }else{
                    Swal.fire({
                      iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                      icon: "info",
                      title: t("global.success"),
                      text: t("vip.success-claim"),
                      confirmButtonText: t("global.ok"),
                      customClass: (!isDesktop) ? {
                      container: 'swal-mobile-container sucess',
                      icon: 'swal-success-icon'
                      } : []
                  });
                }
            } else {
              return false;
            }
          }

        return true;
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item, index) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    applyBonusHandler = (type) => 
    {
        const { t } = this.props;
        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.confirm"),
            cancelButtonText: t("global.cancel"),
            allowEnterKey: false,
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container reminder",
                    icon: "swal-reminder-icon",
                }
                : [],
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                let id = null;
                let slug = null;
                switch (type) 
                {
                    case 'rescue-sport':
                    case 'rescue-slot':
                    case 'rescue-casino':
                       
                        if(type === 'rescue-sport')
                        {
                            id = ((this.state.rescueBonus.sp && this.state.rescueBonus.sp.id) || null);
                        }
                        else if(type === 'rescue-casino')
                        {
                            id = ((this.state.rescueBonus.ca && this.state.rescueBonus.ca.id) || null);
                        }
                        else
                        {
                            id = ((this.state.rescueBonus.sl && this.state.rescueBonus.sl.id) || null);
                        }
                        
                        id = (id !== null) ? id : 0;
                        data.id = id;
                        this.props.funcApplyRescueBonus(data);
                    break;

                    case 'delay-deposit':
                        slug = 'deposit';
                        id = ((this.state.delayBonus.deposit && this.state.delayBonus.deposit.id) || null);
                        id = (id !== null) ? id : 0;
                            
                        data.id = id;
                        data.slug = slug;
                        this.props.funcApplyDelayBonus(data);
                    break;
                    case 'delay-withdrawal':
                        slug = 'withdrawal';
                        id = ((this.state.delayBonus.withdrawal && this.state.delayBonus.withdrawal.id) || null);
        
                        id = (id !== null) ? id : 0;
                        data.id = id;
                        data.slug = slug;
                        this.props.funcApplyDelayBonus(data);
                    break;
                    case 'vip-weekly-bonus':
                        this.props.funcApplyVipWeeklyBonus();
                    break;
                    case 'birthday-bonus':
                        this.props.funcApplyBirthdayBonus();
                    break;
                    case 'first-bet-bonus':
                        this.props.funcApplyFirstBetBonus();
                    break;
                    case 'mobile-app-deposit':
                        data.action = "click";
                        this.props.funcApplyMobileAppsBonus(data);
                    break;
                    case 'voucher-code':
                        let params = {};
                        params.code = this.state.bonusCode;
                        this.props.funcApplyVoucherCode(params);  
                    break;
                    default:
                        Swal.fire({
                            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                            title:  t("global.failed"),
                            icon: "error",
                            html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                            customClass: (!isDesktop) ? {
                            container: "swal-mobile-container failed",
                            icon: "swal-failed-icon", 
                        } : [],
                        });
                    break;
                }
            }
        });
    }

    sesonalClaimHandler = () => {
        let data = {};
        data.action = "click";
        this.props.funcApplyAngbaoBonus(data);
    }

    toggleBonusCodeModal = () => {
        this.setState({
            showBonusCodeModal: !this.state.showBonusCodeModal,
            bonusCode: ""
        })
    }

    onVoucherCodeChange = (bonusCode) => {
        this.setState({
            bonusCode: bonusCode
        });
    }

    render() 
    {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../../assets/images/bonus", false, /\.(png|jpe?g|svg|webp)$/));
        let s3ImgUrl = `${process.env.REACT_APP_S3_URL}/images/icon/bonus`;

        const fadeUp = keyframes`
            from {
            opacity: 0;
            transform: translate3d(0, 50px ,0);
            }

            to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            }`;
        
        return (
            <Auxiliary>
                {
                    <BonusCodeModal
                        toggleBonusCodeModal={this.toggleBonusCodeModal}
                        onVoucherCodeChange={this.onVoucherCodeChange}
                        applyBonusHandler={this.applyBonusHandler}
                        {...this.state}
                        {...this.props}
                    />
                }
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                            <div className="">
                                <div className="">
                                    {/* <div className="pd-card-col">
                                        <h4>{t('profile-sidebar.bonus')}</h4>
                                        {this.state.loading
                                        ?
                                            <Skeleton count={1} height={40} style={{ marginBottom: "1rem" }} />
                                        :
                                            <div className="pd-content">
                                                <div className="bonus-col">
                                                    <div className="row g-3">
                                                        <div className="col-6">
                                                            <div className="bonus-container">
                                                                <div className="bonus-content">
                                                                    <img src={`${s3ImgUrl}/bonus-ico.svg`} alt="bonus-ico" />
                                                                    <div>
                                                                    <label>{t('bonus.total-redemption-amount')}</label>
                                                                    <p>
                                                                        {this.state.loading ? (
                                                                        <Skeleton count={1} />
                                                                        ) : (
                                                                        <>
                                                                            {this.props.currentUser.currency}
                                                                            <span className="ms-2">888.00</span>
                                                                        </>
                                                                        )}
                                                                    </p>
                                                                    </div>
                                                                </div>
                                                                <button className="btn btn-gradient-blue" type="button">
                                                                    {t('bonus.quick-redeem')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div> */}
                                    {this.state.seasonalEventsData && this.state.seasonalEventsData.length > 0 ? (
                                    <div className="pd-card-col mb-3">
                                        <h6>{t('bonus.seasonal-event-bonus')}</h6>
                                        {this.state.loading
                                        ?
                                            <div className="row g-3">
                                                {[...Array(4)].map((x, i) =>
                                                    <div className="col-3" key={i}>
                                                        <Skeleton  count={1} height={130}/>
                                                    </div>
                                                )}                                       
                                            </div>
                                        :
                                            <div className="pd-content">
                                                <div className="bonus-event-container">
                                                    <div className="row g-3">
                                                        {this.state.seasonalEventsData && this.state.seasonalEventsData.map((item, index) => (
                                                            <div className="col-3" key={index}>
                                                                <div className={`bonus-event-card ${item.new && "new-events"}`}>
                                                                    <img className="active" src={img[`${item.img}.webp`]} alt={item.img} />
                                                                    <div className="bonus-event-content">
                                                                    <p>
                                                                        {this.props.currentUser.language_code !== 'zh-CN' ? (<small>{t(`seasonal-event.${item.name}`)}</small>) : t(`seasonal-event.${item.name}`)}
                                                                    </p>                                                                                          
                                                                    {item.url === null ? (
                                                                        <div>
                                                                            {!item.redeem ? (
                                                                                <button
                                                                                    className="btn btn-gradient-blue  bonus"
                                                                                    type="button"
                                                                                    onClick={() => this.applyBonusHandler(item.name)}
                                                                                    disabled={item.redeem ? false : true}
                                                                                >
                                                                                    {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                                                </button>
                                                                            ) : (
                                                                                <button
                                                                                    className="btn btn-gradient-blue  bonus"
                                                                                    onClick={this.sesonalClaimHandler}
                                                                                >
                                                                                    {t('bonus.redeem')}
                                                                                </button>
                                                                            )}                                                                      
                                                                        </div>
                                                                    ) : (
                                                                        <Link className="btn btn-gradient-blue  bonus" to={{ pathname: `${item.url}` }}>
                                                                            {t('bonus.redeem')}
                                                                        </Link>
                                                                    )}                                                                       
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    ) : null}
                                    <div className="pd-card-col mb-3">
                                        <h6>{t('bonus.special-event-bonus')}</h6>
                                        {this.state.loading
                                        ?
                                            <div className="row g-3">
                                                {[...Array(8)].map((x, i) =>
                                                    <div className="col-3" key={i}>
                                                        <Skeleton  count={1} height={130}/>
                                                    </div>
                                                )}                                       
                                            </div>
                                        :
                                            <div className="pd-content">
                                                <div className="bonus-event-container">
                                                    <div className="row g-3">
                                                        {this.state.specialEventsData && this.state.specialEventsData.map((item, index) => (
                                                            <div className="col-3" key={index}>
                                                                <div className={`bonus-event-card w-border ${this.props.currentUser.language_code === "id" ? item.new && "new-events-id" : item.new && "new-events"}`}>
                                                                    <img className="active" src={`${s3ImgUrl}/${item.img}.svg`} alt={item.img} />
                                                                    <p>
                                                                        {this.props.currentUser.language_code !== 'zh-CN' ? (<small>{t(`transaction-type.${item.name}`)}</small>) : t(`transaction-type.${item.name}`)}&nbsp;
                                                                        <span>
                                                                            {
                                                                                (item.name === 'rescue-sport') ? `(${(item.name === 'rescue-sport' && item.percent) || 0}%)` 
                                                                                : (item.name === 'rescue-casino') ? `(${(item.name === 'rescue-casino' && item.percent) || 0}%)`
                                                                                : (item.name === 'rescue-slot') ? `(${(item.name === 'rescue-slot' && item.percent) || 0}%)`
                                                                                : null
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    {
                                                                        (item.url === null) 
                                                                        ?
                                                                            <button className="btn btn-gradient-blue  bonus" type="button" onClick={() => (item.redeem) ? this.applyBonusHandler(item.name) : {}} disabled={(item.redeem) ? false : true}>
                                                                                {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                                            </button> 
                                                                        : (item.url === "modal") ?
                                                                            <button className="btn btn-gradient-blue  bonus" type="button" onClick={ item.redeem ?  this.toggleBonusCodeModal : ""} disabled={(item.redeem) ? false : true}>
                                                                            {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                                            </button>  
                                                                        :
                                                                            <Link className="btn btn-gradient-blue  bonus" to={{ pathname: `${item.url}` }}>
                                                                                {t('bonus.redeem')}
                                                                            </Link>
                                                                    }
                                                                    
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="pd-card-col">
                                        <h6>{t('bonus.long-term-event-bonus')}</h6>
                                        {this.state.loading
                                        ?
                                            <div className="row g-3">
                                                {[...Array(12)].map((x, i) =>
                                                    <div className="col-3" key={i}>
                                                        <Skeleton  count={1} height={130}/>
                                                    </div>
                                                )}                                       
                                            </div>
                                        :
                                            <div className="pd-content">
                                                <div className="bonus-event-container">
                                                    <div className="row g-3">
                                                        {this.state.longEventsData && this.state.longEventsData.map((item, index) => (
                                                            <div className="col-3" key={index}>
                                                                <div className={`bonus-event-card w-border ${item.url === null && item.redeem === false && "w-bg"} ${this.props.currentUser.language_code === "id" ? item.new && "new-events-id" : item.new && "new-events"}
`}>
                                                                    <img className="active" src={`${s3ImgUrl}/${item.img}.svg`} alt={item.img} />
                                                                    <p>
                                                                        {this.props.currentUser.language_code !== 'zh-CN' ? (<small>{t(`transaction-type.${item.name}`)}</small>) : t(`transaction-type.${item.name}`)}&nbsp;
                                                                        <span>
                                                                            {
                                                                                (item.name === 'wallet-balance-interest') ? `(${item.bonus || 0}%)` 
                                                                                : (item.name === 'welcome-bonus') ? `(${(item.name === 'welcome-bonus' && item.bonus) || 0}%)`
                                                                                : (item.name === 'daily-bonus') ? `(${(item.name === 'daily-bonus' && item.bonus) || 0}%)`
                                                                                : (item.name === 'sports-rebate') ? `(${(item.name === 'sports-rebate' && item.bonus) || 0}%)`
                                                                                : (item.name === 'casino-rebate') ? `(${(item.name === 'casino-rebate' && item.bonus) || 0}%)`
                                                                                : (item.name === 'slots-rebate') ? `(${(item.name === 'slots-rebate' && item.bonus) || 0}%)`
                                                                                : (item.name === 'games-rebate') ? `(${(item.name === 'games-rebate' && item.bonus) || 0}%)`
                                                                                : null
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    {
                                                                        // null, if button is onClick, not redirecting
                                                                        (item.url === null) 
                                                                        ?
                                                                            <button className="btn btn-gradient-blue  bonus" type="button" onClick={() => (item.redeem) ? this.applyBonusHandler(item.name): {}} disabled={(item.redeem) ? false : true}>
                                                                                {t('bonus.redeem')} {!item.redeem ? t('bonus.automatically') : ''}
                                                                            </button> 
                                                                        :
                                                                            <Link className="btn btn-gradient-blue  bonus" to={{ pathname: `${item.url}` }}>
                                                                                {t('bonus.redeem')}
                                                                            </Link>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            </Reveal>
                        </div>
                    </section>
                ) : (
                    <MobileBonus
                        {...this.state}
                        {...this.props}
                        seasonalEventsData={this.state.seasonalEventsData}
                        specialEventsData={this.state.specialEventsData}
                        longEventsData={this.state.longEventsData}
                        applyBonusHandler={this.applyBonusHandler} 
                        sesonalClaimHandler={this.sesonalClaimHandler}
                        toggleBonusCodeModal={this.toggleBonusCodeModal}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => 
{
    return {
        currentUser: state.currentUser.data,
        rs: state.bonus.rs,
        rescue_bonus: state.bonus.rescue_bonus,
        first_bet_bonus: state.bonus.first_bet_bonus,
        claim_vip_weekly_bonus: state.vip.claim_vip_weekly_bonus,
        claim_vip_birthday_bonus: state.vip.claim_vip_birthday_bonus,
        claim_angpau_result: state.events.claim_angpau_result,
        voucher_code: state.bonus.voucher_code,
        mobile_apps_promo_claim: state.events.mobile_apps_promo_claim,
        delay_bonus: state.bonus.delay_bonus,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => 
{
    return {
        funcGetList: (params) => dispatch(actions.getList(params)),
        funcApplyRescueBonus: (filter) => dispatch(actions.applyResueBonus(filter)),
        funcApplyVipWeeklyBonus: () => dispatch(claimVIPWeeklyBonus()),
        funcApplyBirthdayBonus: () => dispatch(claimVIPBirthdayBonus()),
        funcApplyFirstBetBonus: () => dispatch(actions.claimFirstBetBonus()),
        funcApplyAngbaoBonus: (data) => dispatch(claimAngpau(data)),
        funcApplyVoucherCode: (data) => dispatch(actions.redeemVoucherCode(data)),
        funcApplyMobileAppsBonus: (data) => dispatch(mobileAppsClaim(data)),
        funcApplyDelayBonus: (filter) => dispatch(actions.applyDelayBonus(filter)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Bonus));
