import React from "react";
import { Helmet, HelmetProvider} from "react-helmet-async";

// Get the current year
const currentYear = new Date().getFullYear();

const defaultSeo = <HelmetProvider><Helmet>
    <meta name="description" content="R77 is the leading online casino sites in Asia. Wide range of online casino games include Live, Slots, Sports & more. Play with R77 now!" />
    <meta name="keywords" content={`Online Casino Malaysia, Best Online Casino Malaysia, Play Online Casino Malaysia, Top Online Casino Malaysia, Professional Online Casino Malaysia, Top Rated Online Casino Malaysia, Trusted Online Casino Malaysia ${currentYear}, Online Casino, Online Casino Malaysia ${currentYear}`}/>    
    <meta property="og:title" content={`R77 | Asia Leading Trusted Online Casino ${currentYear}`} />
    <meta property="og:description" content="R77 is the leading online casino sites in Asia. Wide range of online casino games include Live, Slots, Sports & more. Play with R77 now!" />
    <meta name="twitter:title" content={`R77 | Asia Leading Trusted Online Casino ${currentYear}`} />
    <meta name="twitter:description" content="R77 is the leading online casino sites in Asia. Wide range of online casino games include Live, Slots, Sports & more. Play with R77 now!" />
    {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/`} /> */}
  </Helmet></HelmetProvider>;

const promoSeo = <HelmetProvider><Helmet>
  <meta name="description" content={`Enjoy the best online games promotion now only at ${process.env.REACT_APP_PUBLIC_URL}. The best leading online gaming platfrom in Asia. Play now!`} />	
  <meta property="og:title" content={`Online Games Promotion Malaysia ${currentYear} | Play With R77 Now`} />	
  <meta property="og:description" content={`Enjoy the best online games promotion now only at ${process.env.REACT_APP_PUBLIC_URL}. The best leading online gaming platfrom in Asia. Play now!`} />	
  <meta name="twitter:title" content={`Online Games Promotion Malaysia ${currentYear} | Play With R77 Now`} />	
  <meta name="twitter:description" content={`Enjoy the best online games promotion now only at ${process.env.REACT_APP_PUBLIC_URL}. The best leading online gaming platfrom in Asia. Play now!`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/promos`}/>	 */}
</Helmet></HelmetProvider>;

const sportSeo = <HelmetProvider><Helmet>
  <meta name="description" content="Find the best online sportsbook games in Malaysia? R77 will be your ideal online sportsbook gaming platform in Malaysia. Fast payout. Play now!" />
  <meta name="keywords" content="online sports games Malaysia, online sportsbook Malaysia, online sports games, online games Malaysia, sports games Malaysia, online sports games in Malaysia, play sportsbook games Malaysia" />
  <meta property="og:title" content="Top Rated Online Sportsbook Games Malaysia | R77" />
  <meta property="og:description" content="Find the best online sportsbook games in Malaysia? R77 will be your ideal online sportsbook gaming platform in Malaysia. Fast payout. Play now!" />
  <meta name="twitter:title" content="Top Rated Online Sportsbook Games Malaysia | R77" />
  <meta name="twitter:description" content="Find the best online sportsbook games in Malaysia? R77 will be your ideal online sportsbook gaming platform in Malaysia. Fast payout. Play now!" />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/online-sports-games-malaysia`} /> */}
</Helmet></HelmetProvider>;

const liveCasinotSeo = <HelmetProvider><Helmet>
  <meta name="description" content="R77 offers you with the best online live games in Malaysia to comply all the online users needs. Play more, win more. Visit R77 to play now!" />
  <meta name="keywords" content="online live games malaysia, best online live games, online live games, play online live games, play live games Malaysia" />
  <meta property="og:title" content="Best Online Live Games in Malaysia | R77" />
  <meta property="og:description" content="R77 offers you with the best online live games in Malaysia to comply all the online users needs. Play more, win more. Visit R77 to play now!" />
  <meta name="twitter:title" content="Best Online Live Games in Malaysia | R77" />
  <meta name="twitter:description" content="R77 offers you with the best online live games in Malaysia to comply all the online users needs. Play more, win more. Visit R77 to play now!" />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/online-live-games-malaysia`} /> */}
</Helmet></HelmetProvider>;

const p2pSeoGp = <HelmetProvider><Helmet>
  <meta name="description" content={`Play Gameplay Interactive online p2p games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online p2p Malaysia is now available for mobile & desktop! Play now.`} />
  <meta name="keywords" content="Gameplay Interactive"/>	
  <meta property="og:title" content="Best GamePlay Interactive Online P2P Games Malaysia"/>	
  <meta property="og:description" content={`Play Gameplay Interactive online p2p games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online p2p Malaysia is now available for mobile & desktop! Play now.`} />	
  <meta name="twitter:title" content="Best GamePlay Interactive Online P2P Games Malaysia"/>	
  <meta name="twitter:description" content={`Play Gameplay Interactive online p2p games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online p2p Malaysia is now available for mobile & desktop! Play now.`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/p2p/gameplay-interactive`} />	 */}
</Helmet></HelmetProvider>

const lotterySeoGp = <HelmetProvider><Helmet>
  <meta name="description" content={`Play Gameplay Interactive online lottery games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online lottery Malaysia is now available for mobile & desktop! Play now.`} />
  <meta name="keywords" content="Gameplay Interactive"/>	
  <meta property="og:title" content="Best GamePlay Interactive Online Lottery Games Malaysia"/>	
  <meta property="og:description" content={`Play Gameplay Interactive online lottery games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online lottery Malaysia is now available for mobile & desktop! Play now.`} />	
  <meta name="twitter:title" content="Best GamePlay Interactive Online Lottery Games Malaysia"/>	
  <meta name="twitter:description" content={`Play Gameplay Interactive online lottery games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online lottery Malaysia is now available for mobile & desktop! Play now.`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/p2p/gameplay-interactive`} />	 */}
</Helmet></HelmetProvider>

const slotSeoAg = <HelmetProvider><Helmet>
  <meta name="description" content={`R77 offers famous wagering games including Baccarat, Roulette & more. Visit ${process.env.REACT_APP_PUBLIC_URL} now to play a wide range of best Asia Gaming in Malaysia.`} />
  <meta name="keywords" content="Asia Gaming " />
  <meta property="og:title" content="Asia Gaming Online Slot Games in Malaysia | R77" />
  <meta property="og:description" content={`R77 offers famous wagering games including Baccarat, Roulette & more. Visit ${process.env.REACT_APP_PUBLIC_URL} now to play a wide range of best Asia Gaming in Malaysia.`} />
  <meta name="twitter:title" content="Asia Gaming Online Slot Games in Malaysia | R77" />
  <meta name="twitter:description" content={`R77 offers famous wagering games including Baccarat, Roulette & more. Visit ${process.env.REACT_APP_PUBLIC_URL} now to play a wide range of best Asia Gaming in Malaysia.`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/asia-gaming`} /> */}
</Helmet></HelmetProvider>;

const slotSeoGp = <HelmetProvider><Helmet>
  <meta name="description" content="Gameplay Interactive by R77 offers a wide range  of 2D and 3D live dealer games, including Baccarat, 7Up, Dragon Tiger, Sic Bo, Blackjack & more. Play now!" />
  <meta name="keywords" content="GamePlay Interactive" />
  <meta property="og:title" content="GamePlay Interactive Online Slot Games Malaysia | R77" />
  <meta property="og:description" content="Gameplay Interactive by R77 offers a wide range  of 2D and 3D live dealer games, including Baccarat, 7Up, Dragon Tiger, Sic Bo, Blackjack & more. Play now!" />
  <meta name="twitter:title" content="GamePlay Interactive Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content="Gameplay Interactive by R77 offers a wide range  of 2D and 3D live dealer games, including Baccarat, 7Up, Dragon Tiger, Sic Bo, Blackjack & more. Play now!" />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/gameplay-interactive`} /> */}
</Helmet></HelmetProvider>;

const slotSeoMg = <HelmetProvider><Helmet>
  <meta name="description" content="Microgaming Malaysia by R77 offers all mobile live dealer games. Play all live baccarat, blackjack, roulette wheel and others game at R77 now." />
  <meta name="keywords" content="Micro Gaming" />
  <meta property="og:title" content="Play Micro Gaming Online Slot Games Malaysia | R77" />
  <meta property="og:description" content="Microgaming Malaysia by R77 offers all mobile live dealer games. Play all live baccarat, blackjack, roulette wheel and others game at R77 now." />
  <meta name="twitter:title" content="Play Micro Gaming Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content="Microgaming Malaysia by R77 offers all mobile live dealer games. Play all live baccarat, blackjack, roulette wheel and others game at R77 now." />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/micro-gaming`} /> */}
</Helmet></HelmetProvider>;

const slotSeoIsb = <HelmetProvider><Helmet>
  <meta name="description" content="IsoftBet by R77 is the ultimate online gaming platform that support multiplayer competitions and win money, prizes, rewards & more. Play with R77 now." />
  <meta name="keywords" content="Isoftbet" />
  <meta property="og:title" content="Top Rated I Soft Bet Online Slot Games Malaysia | R77" />
  <meta property="og:description" content="IsoftBet by R77 is the ultimate online gaming platform that support multiplayer competitions and win money, prizes, rewards & more. Play with R77 now." />
  <meta name="twitter:title" content="Top Rated I Soft Bet Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content="IsoftBet by R77 is the ultimate online gaming platform that support multiplayer competitions and win money, prizes, rewards & more. Play with R77 now." />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/isoftbet`} /> */}
</Helmet></HelmetProvider>;

const slotSeoPp = <HelmetProvider><Helmet>
  <meta name="description" content="Pragmatic Play is one of the leading software provider in Malaysia & Asia. Play with R77 now for the best Pragmatic Play gaming experience today!" />
  <meta name="keywords" content="Pragmatic Play" />
  <meta property="og:title" content="Best Pragmatic Play Online Slot Games Malaysia | R77" />
  <meta property="og:description" content="Pragmatic Play is one of the leading software provider in Malaysia & Asia. Play with R77 now for the best Pragmatic Play gaming experience today!" />
  <meta name="twitter:title" content="Best Pragmatic Play Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content="Pragmatic Play is one of the leading software provider in Malaysia & Asia. Play with R77 now for the best Pragmatic Play gaming experience today!" />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/pragmatic-play`} /> */}
</Helmet></HelmetProvider>;

const slotSeoQt = <HelmetProvider><Helmet>
  <meta name="description" content={`Play QTech online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online slots Malaysia is now available for mobile & desktop! Get our amazing welcome bonus now.`} />
  <meta name="keywords" content="Qtech" />
  <meta property="og:title" content="Qtech Online Slot Games in Malaysia | Play With R77 Now" />
  <meta property="og:description" content={`Play QTech online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online slots Malaysia is now available for mobile & desktop! Get our amazing welcome bonus now.`} />
  <meta name="twitter:title" content="Qtech Online Slot Games in Malaysia | Play With R77 Now" />
  <meta name="twitter:description" content={`Play QTech online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! One of the best online slots Malaysia is now available for mobile & desktop! Get our amazing welcome bonus now.`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/qtech`} /> */}
</Helmet></HelmetProvider>;

const slotSeoSp = <HelmetProvider><Helmet>
  <meta name="description" content={`Play Simple Play online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! The top rated online slots gaming platform in Malaysia. Click to explore wide range of slot games today!`} />	<meta name="keywords" content="Simple Play" />
  <meta property="og:title" content="Simple Play Online Slot Games Malaysia | Play With R77 Now" />
  <meta property="og:description" content={`Play Simple Play online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! The top rated online slots gaming platform in Malaysia. Click to explore wide range of slot games today!`} />
  <meta name="twitter:title" content="Simple Play Online Slot Games Malaysia | Play With R77 Now" />
  <meta name="twitter:description" content={`Play Simple Play online slots games from ${process.env.REACT_APP_PUBLIC_URL} today! The top rated online slots gaming platform in Malaysia. Click to explore wide range of slot games today!`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/simple-play`} /> */}
</Helmet></HelmetProvider>;

const slotSeoTtg = <HelmetProvider><Helmet>
  <meta name="description" content={`Play TopTrend Gaming in Malaysia from ${process.env.REACT_APP_PUBLIC_URL}. Amazing prizes & great bonuses are awaiting you to grab. Click to explore our games today!`} />
  <meta name="keywords" content="Top Trend Gaming" />
  <meta property="og:title" content="Play Top Trend Gaming Online Slot Games Malaysia | R77 " />
  <meta property="og:description" content={`Play TopTrend Gaming in Malaysia from ${process.env.REACT_APP_PUBLIC_URL}. Amazing prizes & great bonuses are awaiting you to grab. Click to explore our games today!`} />
  <meta name="twitter:title" content="Play Top Trend Gaming Online Slot Games Malaysia | R77 " />
  <meta name="twitter:description" content={`Play TopTrend Gaming in Malaysia from ${process.env.REACT_APP_PUBLIC_URL}. Amazing prizes & great bonuses are awaiting you to grab. Click to explore our games today!`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/top-trend-gaming`} /> */}
</Helmet></HelmetProvider>;

const slotSeoSbo = <HelmetProvider><Helmet>
  <meta name="description" content="Join SBOBet Malaysia for online sports slot games now from R77.com. SBOBet Malaysia is one of the biggest online mobile platforms to experiece with. Click to play now!" />
  <meta name="keywords" content="Sbobet" />
  <meta property="og:title" content="Top Rated Sbobet Online Slot Games Malaysia | R77" />
  <meta property="og:description" content="Join SBOBet Malaysia for online sports slot games now from R77.com. SBOBet Malaysia is one of the biggest online mobile platforms to experiece with. Click to play now!" />
  <meta name="twitter:title" content="Top Rated Sbobet Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content="Join SBOBet Malaysia for online sports slot games now from R77.com. SBOBet Malaysia is one of the biggest online mobile platforms to experiece with. Click to play now!" />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/sbobet`} /> */}
</Helmet></HelmetProvider>;

const slotSeoEvo = <HelmetProvider><Helmet>
  <meta name="description" content={`Evolution Gaming is the most popular online live games with quality graphic! Play the best Evolution Gaming online slot gaming now at ${process.env.REACT_APP_PUBLIC_URL}`} />
  <meta name="keywords" content="Evolution Gaming" />
  <meta property="og:title" content="Play Evolution Gaming Online Slot Games Malaysia | Play With R77 Now" />
  <meta property="og:description" content={`Evolution Gaming is the most popular online live games with quality graphic! Play the best Evolution Gaming online slot gaming now at ${process.env.REACT_APP_PUBLIC_URL}`} />
  <meta name="twitter:title" content="Play Evolution Gaming Online Slot Games Malaysia | Play With R77 Now" />
  <meta name="twitter:description" content={`Evolution Gaming is the most popular online live games with quality graphic! Play the best Evolution Gaming online slot gaming now at ${process.env.REACT_APP_PUBLIC_URL}`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/evolution-gaming`} /> */}
</Helmet></HelmetProvider>;

const slotSeoSg = <HelmetProvider><Helmet>
  <meta name="description" content={`Play Spadegaming slot games from ${process.env.REACT_APP_PUBLIC_URL}. Spadegaming offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  <meta name="keywords" content="Spade Gaming" />
  <meta property="og:title" content="Best Spade Gaming Online Slot Games Malaysia | R77" />
  <meta property="og:description" content={`Play Spadegaming slot games from ${process.env.REACT_APP_PUBLIC_URL}. Spadegaming offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  <meta name="twitter:title" content="Best Spade Gaming Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content={`Play Spadegaming slot games from ${process.env.REACT_APP_PUBLIC_URL}. Spadegaming offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/spadegaming`} /> */}
</Helmet></HelmetProvider>;

const slotSeoPg = <HelmetProvider><Helmet>
  <meta name="description" content={`Play Pocket Games Soft slot games from ${process.env.REACT_APP_PUBLIC_URL}. Pocket Games Soft offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  <meta name="keywords" content="Spade Gaming" />
  <meta property="og:title" content="Best Spade Gaming Online Slot Games Malaysia | R77" />
  <meta property="og:description" content={`Play Pocket Games Soft slot games from ${process.env.REACT_APP_PUBLIC_URL}. Pocket Games Soft offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  <meta name="twitter:title" content="Best Spade Gaming Online Slot Games Malaysia | R77" />
  <meta name="twitter:description" content={`Play Pocket Games Soft slot games from ${process.env.REACT_APP_PUBLIC_URL}. Pocket Games Soft offers the best gaming solutions, excellent integration & more to meet all the users needs. Play now!`} />
  {/* <link rel="canonical" href={`${process.env.REACT_APP_PUBLIC_URL}/slot/pocket-games-soft`} /> */}
</Helmet></HelmetProvider>;

export { defaultSeo, promoSeo, sportSeo, liveCasinotSeo, p2pSeoGp, lotterySeoGp, slotSeoAg, slotSeoGp, slotSeoMg, slotSeoIsb, slotSeoPp, slotSeoQt, slotSeoSp, slotSeoTtg, slotSeoSbo, slotSeoEvo, slotSeoSg, slotSeoPg };