import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import moment from "moment-timezone";
import slugConf from "../../common/data/games.json";
import Skeleton from "react-loading-skeleton";
import { CgDanger } from 'react-icons/cg';
import { isDesktop } from 'react-device-detect';
import { Helmet } from "react-helmet";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";

//Components
import Banner from "../../components/Banner/Banner";
import GamePagination from "../../components/Pagination/GamePagination";
import MobileProviderGames from "../MobileProviderGames/MobileProviderGames";
import { slotSeoAg, slotSeoEvo, slotSeoGp, slotSeoIsb, slotSeoMg, slotSeoPp, slotSeoQt, slotSeoSbo, slotSeoSg, slotSeoSp, slotSeoTtg, slotSeoPg } from "../../components/SeoMeta/SeoMeta";

//Icons
import { Icon } from "@iconify/react";

//Store
import * as action from '../../store/ducks/slot/actions';
import * as gameFavAction from "../../store/ducks/gameFav/actions";

class Slot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      vendorId: '',
      vendorList: [],
      vendorSlug: "",
      gameList: [],
      tmpGameList: [],
      fav: "",
      category: "",
      search: "",
      demoUrl: "",
      imageUrl: "",
      pageLimit: 30,
      curPage: 1,
      totalPage: 0,
      defaultSlug: "",
      memberFavGameList: []
    }
    this.vendorRef = createRef();
  }

  componentDidMount() {
    let params = {};
    // params.country_id = this.props.currentUser.data.country_id;
    // params.language_code = this.props.currentUser.data.language_code;
    params.fav = this.state.fav;
    params.category = this.state.category;
    // params.country_code = this.props.currentUser.data.country_code;
    params.limit = this.state.pageLimit;
    params.search = this.state.search;

    const path = window.location.pathname.split("/");
    if (path.length >= 3) {
      let curSlug = path[2];
      curSlug = ((slugConf['link-slug'] && slugConf['link-slug'][curSlug]) || '');
      this.setState({
        vendorSlug: curSlug
      });
      params.vendor_slug = curSlug;
      // console.log("[componentDidMount.slug]",curSlug);
    }
    this.props.getVendors(params);
  }

  // getSnapshotBeforeUpdate(prevProps, prevState) {
  //   // console.log('snapshot before update', prevProps, prevState);
  //   const path = window.location.pathname.split("/");
  //   if (path !== undefined && path.length >= 3 && path[2] !== '') {
  //     if(isDesktop){
  //       this.vendorRef.current.scrollIntoView({ behavior: "auto", block: "start" });
  //     }
  //   }

  //   return null;
  // }

  componentDidUpdate(prevProps, prevState, snapshot) { }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log('should component update',nextProps, nextProps.location.state);
    if(nextProps.location!==undefined && nextProps.location.state!==undefined && nextProps.location.state!==this.props.location.state){
      this.setState({
        vendorSlug: nextProps.location.state.vendorSlug,
        loading: true,
        fav: (nextProps.location.state.vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
        search: '',
      },()=>{
        let params = {};
        params.country_id = this.props.currentUser.data.country_id;
        params.language_code = this.props.currentUser.data.language_code;
        params.country_code = this.props.currentUser.data.country_code;
        params.vendor_slug = nextProps.location.state.vendorSlug;
        params.fav = this.state.fav;
        params.category = this.state.category;
        params.limit = this.state.pageLimit;
        params.search = this.state.search;
        if(localStorage.getItem("accessToken")){
          params.buffer = this.props.currentUser.data.id;
        }
        this.props.getGames(params);

        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "sl";
          data.slug = nextProps.location.state.vendorSlug;
          this.props.getGameFavList(data);
        }
      });
    }

    if (nextProps.vendor !== undefined && nextProps.vendor !== this.props.vendor) {
      if (nextProps.vendor.responseCode === 200 && nextProps.vendor.msgType === "success") {
        let vendorList = nextProps.vendor.data.vendor_list;
        let vendorSlug = this.state.vendorSlug;
        let defaultSlug = this.state.defaultSlug;
        if (vendorList.length > 0) {
          vendorList.forEach((val) => {
            vendorSlug = vendorSlug !== "" ? vendorSlug : val.game_vendor_slug;
          });
          defaultSlug = defaultSlug !== "" ? defaultSlug : vendorList[0].game_vendor_slug;
        }
        this.getVendorGames(vendorSlug, nextProps.vendor.data.vendor_list, defaultSlug);
      }
    }

    if (nextProps.game !== undefined && nextProps.game !== this.props.game) {
      if (nextProps.game.responseCode === 200 && nextProps.game.msgType === 'success') {
        this.setState({
          loading: false,
          gameList: nextProps.game.data.game_list.row,
          tmpGameList: nextProps.game.data.game_list.row,
          demoUrl: nextProps.game.data.demo_url,
          imageUrl: nextProps.game.data.image_url,
          curPage: nextProps.game.data.game_list.pagination.current_page,
          totalPage: nextProps.game.data.game_list.pagination.total_page
        });
      }
    }

    // still using?
    // console.log('nextProps.rs',nextProps.rs);
    if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
      if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === "success") {
        Swal.close();
        if (nextProps.rs.data.hasOwnProperty("url")) {
          window.open(nextProps.rs.data.url);
        } else if (nextProps.rs.data.hasOwnProperty("gameError")) {
          this.gameErrorResponse(nextProps.rs.data.gameError);
        }
      } else {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} (${nextProps.rs.message[0]}). ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
        });
      }
    }

    if(nextProps.game_fav!==undefined && nextProps.game_fav!==this.props.game_fav){
      if(nextProps.game_fav.responseCode===200 && nextProps.game_fav.msgType==="success"){
        this.setState({
          memberFavGameList: nextProps.game_fav.data.favorite_list
        });
      }
    }

    if(nextProps.update_game_fav!==undefined && nextProps.update_game_fav!==this.props.update_game_fav){
      if(nextProps.update_game_fav.responseCode===200 && nextProps.update_game_fav.msgType==="success"){
        if(localStorage.getItem("accessToken")){
          let data = {};
          data.buffer = this.props.currentUser.data.id;
          data.type = "sl";
          data.slug = this.state.vendorSlug;
          this.props.getGameFavList(data);

          if(this.state.fav===2){
            // refetch game list if updates occur at favorite tab
            this.setState({
              loading: true
            }, () => {
              let params = {};
              params.country_id = this.props.currentUser.data.country_id;
              params.language_code = this.props.currentUser.data.language_code;
              params.country_code = this.props.currentUser.data.country_code;
              params.vendor_slug = this.state.vendorSlug;
              params.fav = this.state.fav;
              params.category = this.state.category;
              params.limit = this.state.pageLimit;
              params.search = this.state.search;
              params.buffer = this.props.currentUser.data.id;
  
              this.props.getGames(params);
            });
          }
        }
      }
    }

    return true;
  }

  gameErrorResponse = (response) => {
    const { t } = this.props;

    switch (response.status) {
      case 1:
        Swal.fire({
          iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
          title:  t("global.reminder"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
          showConfirmButton: true,
          confirmButtonText: `${t("global.refresh")}`,
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon", 
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      case 2:
        const currentUserTimeZone = this.props.currentUser.data.time_zone;
        const startTime = moment(response.maintenance_start_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const endTime = moment(response.maintenance_end_time)
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("YYYY/MM/DD HH:mm:ss")
          .toString();
        const timeZone = moment()
          .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
          .format("[(GMT] z[)]")
          .toString();
        Swal.fire({
          iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">': "",
          icon: "info",
          titleText: `${t("global.under-maintenance")}`,
          html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon", 
        } : [],
        });
        break;
      case 3:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
      default:
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
          title:  t("global.failed"),
          icon: "error",
          html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
          showConfirmButton: true,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon", 
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.reload();
          }
        });
        break;
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  getVendorGames = (vendorSlug, vendorList, defaultSlug) => {
    this.setState({
      vendorSlug: vendorSlug,
      vendorList: vendorList,
      defaultSlug: defaultSlug,
      fav: (vendorSlug === 'pp') ? 1 : '', // pp set "hot" category as default, others vendor default as "all" category
      loading: true,
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.country_code = this.props.currentUser.data.country_code;
      params.vendor_slug = vendorSlug;
      params.fav = (vendorSlug === 'pp') ? 1 : ''; // pp set "hot" category as default, others vendor default as "all" category
      params.category = this.state.category;
      params.limit = this.state.pageLimit;
      params.search = this.state.search;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGames(params);

      if(localStorage.getItem("accessToken")){
        let data = {};
        data.buffer = this.props.currentUser.data.id;
        data.type = "sl";
        data.slug = vendorSlug;
        this.props.getGameFavList(data);
      }
    });
  }

  getFavGames = (e, fav, category) => {
    e.preventDefault();
    this.setState({
      loading: true,
      fav: fav,
      category: (category || ''),
      gameList: []
    }, () => {
      let params = {};
      params.country_id = this.props.currentUser.data.country_id;
      params.language_code = this.props.currentUser.data.language_code;
      params.country_code = this.props.currentUser.data.country_code;
      params.vendor_slug = this.state.vendorSlug;
      params.fav = fav;
      params.category = category;
      params.limit = this.state.pageLimit;
      params.search = this.state.search;
      if(localStorage.getItem("accessToken")){
        params.buffer = this.props.currentUser.data.id;
      }
      this.props.getGames(params);
    })
  }

  searchHandler = event => {
    // let newGameList = [...this.state.tmpGameList]; 
    // let filterGames = [];
    // let searchValue = event.target.value.toLowerCase().replace(/\\/,"");
    // filterGames = newGameList.filter((data)=>{
    // return data.game_title.toLowerCase().search(searchValue) !== -1;
    // });
    this.setState({
      // gameList: event.target.value==='' ? this.state.tmpGameList : filterGames,
      search: event.target.value,
      loading: true
    });
    let params = {};
    params.country_id = this.props.currentUser.data.country_id;
    params.language_code = this.props.currentUser.data.language_code;
    params.country_code = this.props.currentUser.data.country_code;
    params.vendor_slug = this.state.vendorSlug;
    params.fav = this.state.fav;
    params.category = this.state.category;
    params.search = event.target.value.toLowerCase().replace(/\\/, "");
    params.limit = this.state.pageLimit;
    if(localStorage.getItem("accessToken")){
      params.buffer = this.props.currentUser.data.id;
    }
    this.props.getGames(params);
  }

  accessGame = (game) => {
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon',
          } : []
        });
    } else {
      let gameName = game.replace('||', '@@');
      localStorage.setItem("gameLauncher", 'slot|' + gameName + '|' + this.state.vendorSlug);
      window.open('/game-launcher');
    }
  }

  accessDemo = (game) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon',
          } : []
        });
    }
    else{
      let gameName = game.replace('||', '@@');
      localStorage.setItem("gameLauncher", 'slot|' + gameName + '|' + this.state.vendorSlug + '|' + (this.state.demoUrl || 'demo'));
      window.open('/game-launcher');
    }
  }

  navigateList = (event, type, customPage = 0) => {
    event.preventDefault();
    let params = {};
    params.country_id = this.props.currentUser.data.country_id;
    params.language_code = this.props.currentUser.data.language_code;
    params.country_code = this.props.currentUser.data.country_code;
    params.vendor_slug = this.state.vendorSlug;
    params.fav = this.state.fav;
    params.category = this.state.category;
    params.limit = this.state.pageLimit;
    params.search = this.state.search;
    if(localStorage.getItem("accessToken")){
      params.buffer = this.props.currentUser.data.id;
    }
    switch (type) {
      case "page":
        params.page = customPage === 0 ? this.state.curPage : customPage;
        break;
      case "prev":
        params.page = this.state.curPage !== 1 ? this.state.curPage - 1 : this.state.curPage;
        break;
      case "next":
        params.page = this.state.curPage < this.state.totalPage ? this.state.curPage + 1 : this.state.curPage;
        break;
      default:
        params.page = this.state.curPage;
        break;
    }
    this.setState({ loading: true });
    this.props.getGames(params);
  }

  updateMemberFavGame = (event, id) => {
    if(!localStorage.getItem("accessToken")){
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: t("global.login-proceed"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container sorry",
            icon: "swal-sorry-icon",
          } : []
        });
    }
    else{
      let params = {};
      params.buffer = this.props.currentUser.data.id;
      params.alt_buffer = id;
      this.props.updateGameFav(params);
    }
  }

  render() {
    const { t } = this.props;
    const gameIcon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const favIcon = this.imageImport(
      require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/)
    );


    const tmpImg = this.imageImport(require.context("../../assets/images/placeholder/", false, /\.(png|jpe?g|svg)$/));
    let languageCode = this.props.currentUser.data.language_code;
    // let agLang = languageCode === "zh-CN" ? "ZH" : "EN";
    let qtLang = languageCode === "zh-CN" ? "zh_CN" : "en_US";
    let lang = languageCode === "zh-CN" ? "zh_CN" : "en_US";
    let s3ImageUrl = process.env.REACT_APP_S3_IMG_URL;
    let imageUrl = this.state.imageUrl ? this.state.imageUrl : "";
    let seoMeta = null;

    let gameCategory = this.state.vendorList ? this.state.vendorList : [];
    let selectedVendor = this.state.vendorList.filter((val) => val.game_vendor_slug === this.state.vendorSlug);

    let games = this.state.gameList ? this.state.gameList.map((val, idx) => {
      let title = val.game_title === null ? `${this.state.vendorSlug}.${val.game_code}` : val.game_title;
      let img_url = '';
      let css = '';
      switch (this.state.vendorSlug) {
        // case 'ag':
        //   img_url = `${imageUrl.replace("game_code", val.game_code).replace("lang", agLang)}`;
        //   break;
        case 'qt':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-qt`)}</title></Helmet>{slotSeoQt}</>
          img_url = `${imageUrl.replace("game_code", val.game_code).replace("lang", qtLang)}`;
          break;
        case 'ag':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-ag`)}</title></Helmet>{slotSeoAg}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${val.game_code}.png`;
          css = 'square-box';
          break;
        case 'evo':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-evo`)}</title></Helmet>;{slotSeoEvo}</> 
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${val.game_code}.png`;
          css = 'square-box';
          break;
        case 'pt':
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${val.game_code}.png`;
          break;
        case 'isb':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-isb`)}</title></Helmet>{slotSeoIsb}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${val.game_code.toLowerCase()}.png`;
          break;
        case 'mg':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-mg`)}</title></Helmet>{slotSeoMg}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${val.game_code.toLowerCase()}.png`;
          css = 'square-box';
          break;
        case 'pp':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-pp`)}</title></Helmet>{slotSeoPp}</>
          img_url = `${imageUrl}${val.game_code}.png`;
          css = 'square-box';
          break;
        case 'ttg':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-ttg`)}</title></Helmet>{slotSeoTtg}</>
          img_url = `${imageUrl}${val.game_code.split("||")[1]}.png`;
          css = 'square-box';
          break;
        case 'sp':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-sp`)}</title></Helmet>{slotSeoSp}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${lang}/${val.game_code}.jpg`;
          break;
        case 'gp':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-gp`)}</title></Helmet>{slotSeoGp}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${val.game_code}.jpg`;
          break;
        case 'sbo':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-sbo`)}</title></Helmet>{slotSeoSbo}</> 
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${val.game_code}.jpg`;
          css = 'square-box';
          break;
        case 'gd':
          img_url = `https://www.blipbop.com/resources/${val.game_code}_preview_resize.jpg`;
          // imageUrl.replace('{game_code}', val.game_code);
          break;
        case 'sg':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-sg`)}</title></Helmet>{slotSeoSg}</> 
          img_url = `${imageUrl}${val.game_code}.jpg`;
          break;
        case 'net':
        case 'png':
        case 'qs':
          img_url = `${s3ImageUrl}/fg/${lang}/${val.game_code}.png`;
          css = 'square-box';
          break;
        case 'pg':
          seoMeta = <><Helmet><title>R77 | {t(`routes:title.slot-pg`)}</title></Helmet>{slotSeoPg}</>
          img_url = `${s3ImageUrl}/${this.state.vendorSlug}/${val.game_code.toLowerCase()}.jpg`;
          break;
        default:
          img_url = tmpImg['tmp_image.png'];
          break;
      }
      return {
        id: val.id,
        name: title,
        code: val.game_code,
        demo: val.support_demo,
        img_url: img_url,
        rtg_rate: val.rtg_rate,
        css: css,
      }
    }) : [];

    const truncate = (value) =>
    value?.length > 17 ? `${value.substring(0, 17)}...` : value;

    return (
      <Auxiliary>
        {seoMeta !== null && seoMeta}
        {
          isDesktop ? (
            <>
              <Banner category="slot" defaultSlotVendor={this.state.defaultSlug} />
              <section className="global-game-section">
                <div className="x-container">
                  <div className="game-container p-4">
                    <div className="game-category">
                      <ul className="row p-0 g-3">
                        {gameCategory &&
                          gameCategory.map((item, index) => (
                            <li className="game-ctg-col" key={index}>
                              <Link className={`game-link ${item.hot_status === 1 ? 'hot' : ''} ${this.state.vendorSlug === item.game_vendor_slug ? "active" : ""} ${this.state.loading ? "disabled" : ""}`} to={{ pathname: `/slot/${((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || '')}`, state: { vendorSlug: item.game_vendor_slug, vendorId: item.game_vendor_id } }}>
                                <span>
                                  <img
                                    className="icon-grey"
                                    src={gameIcon[`${item.game_vendor_slug}-grey.svg`]}
                                    alt="gpi-gameIcon"
                                  />
                                  <img
                                    className="icon-white"
                                    src={gameIcon[`${item.game_vendor_slug}-white.svg`]}
                                    alt="gpi-gameIcon"
                                  />
                                </span>
                                <p>{t(`vendor.${item.game_vendor_slug}`)}</p>
                              </Link>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="game-header my-4">
                      <ul>
                        <p>{t("global.sort-by")}:</p>
                        <li onClick={(event) => this.getFavGames(event, "")} className={this.state.fav === "" ? "active" : null}>
                          <Icon icon="mdi:view-grid" />
                          <span>{t("global.all")}</span>
                        </li>                        
                        <li onClick={(event) => this.getFavGames(event, 1)} className={this.state.fav === 1 ? "active" : null}>
                          <Icon icon="ic:round-local-fire-department" />
                          <span>{t("global.hot")}</span>
                        </li>
                        <li onClick={(event) => this.getFavGames(event, 2)} className={this.state.fav === 2 ? "active" : null}>
                          <Icon icon="mdi:cards-heart" />
                          <span>{t("global.favorite")}</span>
                        </li>
                        <li onClick={(event) => this.getFavGames(event, 3, 'buy-bonus')} className={this.state.fav === 3 ? "active" : null}>
                          <Icon icon="ri:money-dollar-circle-fill" />
                          <span>{t("game-page.buy-bonus")}</span>
                        </li>
                      </ul>
                      <div className="search-bar">
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("global.search")}
                          aria-label="Search"
                          onChange={(event) => this.searchHandler(event)}
                          value={this.state.search}
                        />
                        <Icon icon="uil:search" />
                      </div>
                    </div>

                    <div className="game-list" ref={this.vendorRef}>
                      <div className="row g-3">
                        {this.state.loading ? (<>
                          {(() => {
                            let display = [];
                            for (var i = 0; i < 10; i++) {
                              display.push(
                                <div className="col-5ths" key={i}>
                                  <div className="logo-cont">
                                    <Skeleton count={1} />
                                  </div>
                                  <div className="d-flex align-items-center p-2 title-cont">
                                    <Skeleton count={1} width={80} />
                                    <img className="ms-auto" src={gameIcon[`${this.state.vendorSlug}-grey.svg`]} alt={this.state.vendorSlug} />
                                  </div>
                                </div>
                              );
                            }
                            return display;
                          })()}
                        </>) :
                        (<>
                          {(()=>{
                            if(this.state.fav===2 && !localStorage.getItem("accessToken")){
                              return (
                                <div className="col-12 text-center">{t("game-page.no-info")}</div>
                              );
                            }
                            else{
                              return (
                                games &&
                                games.map((game, index) => (
                                  <div className="col-4 col-5ths" key={index}>
                                    <div className={`logo-cont ${game.css}`}>
                                      <img
                                        className="w-100"
                                        src={game.img_url}
                                        onError={(e) => {
                                          e.target.onerror = null;
                                          e.target.src = this.src = tmpImg["tmp_image.png"];
                                        }}
                                        alt={game.code}
                                      />
      
                                      {/* {(()=>{
                                        if(this.state.memberFavGameList.length>0 && this.state.memberFavGameList.includes(game.id)){
                                          return (
                                            <img role="button" className="fav" src={favIcon["fav-blue.svg"]} alt="fav"
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                        else{
                                          return (
                                            <img role="button" className="fav" src={favIcon["fav-gray.svg"]} alt="fav"
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                      })()} */}
      
                                      <div className="overlay">
                                        {(() => {
                                          if (gameCategory !== undefined && gameCategory.length !== 0 && selectedVendor !== undefined && selectedVendor.length !== 0) {
                                            if (selectedVendor[0].maintenance_status === 0) {
                                              return (
                                                <>
                                                  <div role="button" className="play-now" onClick={() => this.accessGame(game.code)}>
                                                    {t("global.play-now")}
                                                  </div>
      
                                                  {game.demo === 1 && (
                                                    <div role="button" className="demo" onClick={() => this.accessDemo(game.code)}>
                                                      {t("global.demo")}
                                                    </div>
                                                  )}
                                                </>
                                              );
                                            }
                                            else {
                                              return (
                                                <div className="under-maintenance-text">
                                                  <CgDanger />
                                                  <p>
                                                    {t("global.under-maintenance")}
                                                    <br />
                                                    {t("global.will-be-back-soon")}!
                                                  </p>
                                                </div>
                                              );
                                            }
                                          }
                                        })()}
                                      </div>
                                    </div>
                                    <div className="d-flex align-items-center p-2 title-cont">
                                      <div>
                                       <h6 className="mb-0">{truncate(game.name)}</h6>
                                        <p className="rtp">{t("game-page.rtg")}:<span>{game.rtg_rate.toString()}%</span></p>
                                      </div>
                                      {/* <img
                                        className="ms-auto"
                                        src={gameIcon[`${this.state.vendorSlug}-grey.svg`]}
                                        alt="gpi-black"
                                      /> */}
                                            
                                            {(()=>{
                                        if(this.state.memberFavGameList.length>0 && this.state.memberFavGameList.includes(game.id)){
                                          return (
                                            <img role="button" className="fav ms-auto" src={favIcon["fav-blue.svg"]} alt="fav" 
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                        else{
                                          return (
                                            <img role="button" className="fav ms-auto" src={favIcon["fav-gray.svg"]} alt="fav"
                                              onClick={(event) => this.updateMemberFavGame(event,game.id)}
                                            />
                                          );
                                        }
                                      })()}
                                    </div>
                                  </div>
                                ))
                              );
                            }
                          })()}
                        </>)}
                      </div>
                      <GamePagination 
                        totalPage={this.state.totalPage}
                        curPage={this.state.curPage}
                        data={games}
                        navigateList={(event, type, page)=> this.navigateList(event, type, page)}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <MobileProviderGames 
              returnPage={"/slot"}
              title={t(`vendor.${this.state.vendorSlug}`)}
              gamesList={games}
              gameCategory={gameCategory}
              selectedVendor={selectedVendor}
              gameType="slot"
              memberFavGamesList={this.state.memberFavGameList}
              searchHandler={this.searchHandler}
              accessGameFunc={this.accessGame}
              accessDemoFunc={this.accessDemo}
              navigateList={this.navigateList}
              updateMemberFavGame={this.updateMemberFavGame}
              changeTab={this.getFavGames}
              {...this.state}
              {...this.props}
              bonusBtn={true}
            />
          )
        }

      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    vendor: state.slot.vendor,
    game: state.slot.game,
    rs: state.slot.rs,
    currentUser: state.currentUser,
    game_fav: state.gameFav.game_fav,
    update_game_fav: state.gameFav.update_game_fav
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getVendors: (params) => dispatch(action.getVendorList(params)),
    getGames: (params) => dispatch(action.getGameList(params)),
    getSlotAccess: (params, vendor) => dispatch(action.accessGame(params, vendor)),
    getGameFavList: (params) => dispatch(gameFavAction.getGameFavList(params)),
    updateGameFav: (params) => dispatch(gameFavAction.updateGameFav(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces('translation')(Slot));
