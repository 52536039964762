import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { getPromotedEvents } from "../../store/ducks/promotedEvents/actions";
import {
  socialMediaUserVerification,
  getTopProfile,
  updateMemberPopUp,
} from "../../store/ducks/profile/actions";
import { isDesktop } from "react-device-detect";
import queryString from "query-string";
import axios from "axios";
import { deviceType } from "react-device-detect";
import Swal from "sweetalert2";
import HomeAngpaoEvent from "../Events/HomeAngpaoEvent/HomeAngpaoEvent";
import AngpaoRain from "../Events/AngpaoRain/AngpaoRain";
import { claimAngpau } from "../../store/ducks/angpau/actions";
import RayaEvents from "../Events/RayaEvents/RayaEvents";
import { updateViewCount } from "../../store/ducks/referral/actions";
import merdekaVideo from "../../assets/images/home/merdeka/firework.mp4";
import mooncakeVideo from "../../assets/images/home/mooncake/lantern.mp4";
import halloweenVideo from "../../assets/images/home/halloween/maple.mp4";
//Components 
import Banner from "../../components/Banner/Banner";

import { postLogin } from "../../store/ducks/auth/actions";
import { getSabaOdds } from "../../store/ducks/home/actions";

import BackupUrlModal from "../../components/BackupUrlModal/BackupUrlModal";
// import CountDown from "../../components/CountDown/CountDown";
// import LiveStreaming from "../LiveStreaming/LiveStreaming";

// import Snowfall from "react-snowfall";
// import EventSection from "./HomeSections/EventSection";
import MainSection from "./HomeSections/MainSection";
import EventSectionNew from "./HomeSections/EventSectionNew";
import ProductOverview from "./HomeSections/ProductOverview";
import domain from "../../common/data/domain.json";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import DewaliEvent from "../Events/DewaliEvent/DewaliEvent";
import { childContextTypes } from "qrcode.react";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
  dynamicCountry = "indonesia";
}

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      promotedVideoId: "",
      loading: true,
      // showBackupUrlModal: this.props.history.location.state?.showBackupUrlModal
      //   ? true
      //   : false,
      showBackupUrlModal: false,
      backupUrlValue: "",

      // Switch Event Theme
      currentEvent: "raya",
      showAngpau: false,
      bonusStatus: false,
      homePageEvents: true,
      angpaoAmt: "",
      sabaOdds: [],
      angpaoRain: true,
      showBonanzaModal: true,
      showBonanza: !localStorage.getItem('noBonanza') ? true : false      
    };
  }

  componentDidMount() {
    const data = {};

    // let domains = domainMapping;
    // let domainContent = null;
    // if (domains.my.key.includes(window.location.host)) {
    //     domainContent = domains.my.data;
    // } else if (domains.th.key.includes(window.location.host)) {
    //     domainContent = domains.th.data;
    // } else if (domains.id.key.includes(window.location.host)) {
    //     domainContent = domains.id.data;
    // } else if (domains.vn.key.includes(window.location.host)) {
    //     domainContent = domains.vn.data;
    // } else if (domains.us.key.includes(window.location.host)) {
    //     domainContent = domains.us.data;
    // }

    // if (domainContent === null) {
    //     data.country_id = 1;
    //     data.lang_id = 1;
    // } else {
    //     data.country_id = domainContent[1];
    //     data.lang_id = this.props.currentUser.language_id;
    // }
    // data.country_id = this.props.currentUser.country_id;
    // data.lang_id = this.props.currentUser.language_id;
    // console.log("Home",data);
    this.props.getPromotedEvents(data);
    this.props.getSabaOdds();

    if (localStorage.getItem("accessToken")) {
      this.props.getTopProfile();

      this.props.claimAngpau();
    }

    if (!isDesktop) {
      const { t } = this.props;
      // Check which country and set the banner, annoucement to that specific country data
      let googleCredentials = queryString.parse(this.props.location.hash);
      let googleAccessToken = googleCredentials["access_token"];
      if (googleAccessToken) {
        //Call the Google API to get the user info
        axios
          .get(
            process.env.REACT_APP_GOOGLE_URL +
            "/oauth2/v1/userinfo?alt=json&access_token=" +
            googleAccessToken
          )
          .then((response) => {
            if (
              localStorage.getItem("accessToken") !== null ||
              undefined ||
              ""
            ) {
              sessionStorage.setItem(
                "google_user_info",
                JSON.stringify(response.data)
              );
              localStorage.setItem("google_user_info", response.data.email);

              const googleData = {};
              googleData.google_id = response.data.id;
              googleData.social_media_type = "google";
              googleData.google_link_email = response.data.email;
              googleData.deviceType = "mobile";
              this.props.socialMediaUserVerification(googleData);
            } else {
              sessionStorage.setItem(
                "google_user_info",
                JSON.stringify(response.data)
              );
              // localStorage.setItem("google_user_info", response.data.email);

              const googleData = {};
              googleData.google_id = response.data.id;
              googleData.device_type = deviceType;
              let params = this.encryptionAuth(googleData);

              this.props.postLogin(params);
            }
          })
          .catch((err) => {
            Swal.fire({
              iconHtml: !isDesktop
                ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
                : "",
              title: t("page-header.error-google-login"),
              text: err,
              icon: "error",
              confirmButtonText: t("global.ok"),
              customClass: !isDesktop
                ? {
                  container: "swal-mobile-container failed",
                  icon: "swal-failed-icon",
                }
                : [],
            });
          });
      }
    }

    this.refCodeCheckingAndMobileRedirect();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.promoted_events_data !== this.props.promoted_events_data) {
      if (nextProps.promoted_events_data.responseCode === 200) {
        let promotedEventsData = nextProps.promoted_events_data.data;

        if (promotedEventsData.length !== 0) {
          this.setState({
            data: nextProps.promoted_events_data.data,
            loading: false,
            promotedVideoId: nextProps.promoted_events_data.data[0].video_id,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
      } else {
        return false;
      }
    }

    if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
      if (nextProps.claim_angpau_result.responseCode === 200) {
        if (nextProps.claim_angpau_result.data.eligible === false) {
          this.setState({
            showAngpau: false,
          });
        } else {
          this.setState({
            showAngpau: true,
          });
        }
      } else {
        return false;
      }
    }

    if (nextProps.login_data !== this.props.login_data) {
      if (nextProps.login_data.responseCode === 200) {
        Swal.close();
        this.setState({ isLoading: false });

        let forceReload = false;
        if (
          sessionStorage.getItem("telegram_user_info") ||
          sessionStorage.getItem("google_user_info")
        ) {
          forceReload = true;
        }
        sessionStorage.removeItem("telegram_user_info");
        sessionStorage.removeItem("google_user_info");

        let loginData = nextProps.login_data.data;
        const loggedData = {};
        loggedData.id = loginData.id;
        loggedData.username = loginData.username;
        loggedData.name = loginData.name;
        loggedData.user_group = loginData.user_group;
        loggedData.country_id = loginData.country_id;
        loggedData.country_code = loginData.country_code;
        loggedData.currency = loginData.currency;
        loggedData.language_id = loginData.language_id;
        loggedData.language_code = loginData.language_code;
        loggedData.member_group_id = loginData.group_id;
        loggedData.group_id = loginData.group_id;
        loggedData.contact_no = loginData.contact_no;
        loggedData.email = loginData.email;
        loggedData.balance = loginData.balance;
        loggedData.register_date_time = loginData.created_at;
        loggedData.time_zone = loginData.time_zone;
        this.props.getUserData(loggedData);

        localStorage.setItem("accessToken", loginData.access_token);
        localStorage.setItem(
          "lang",
          loginData.language_code.replace("-", "_").toString().toLowerCase() ||
          "en"
        );

        // reset vip details page if diff country id
        localStorage.removeItem('vipDetails');

        this.props.getTopProfile();

        if (isDesktop) {
          this.props.history.push("/");
          if (forceReload) {
            // fix weird bug where non-myr acc login with social media at myr-domain refuse to set member's country id correctly
            // this section not triggering at desktop view
            window.location.reload();
          }
        } else {
          this.props.history.push(`/online-sports-games-${dynamicCountry}`);
          if (forceReload) {
            // fix weird bug where non-myr acc login with social media at myr-domain refuse to set member's country id correctly
            window.location.reload();
          }
        }

        this.setState({
          username: "",
          password: "",
          openModal: false,
          login: true,
        });
      } else if (nextProps.login_data.responseCode === 422) {
        this.setState({
          isLoading: false,
          errorMsg: nextProps.login_data.message.input_error || [],
          formError: true,
        });
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
            : "",
          title: t("global.failed"),
          text: nextProps.login_data.message,
          // icon: nextProps.login_data.msgType,
          icon: "error",
          confirmButtonText: t("global.ok"),
          customClass: !isDesktop
            ? {
              container: "swal-mobile-container failed",
              icon: "swal-failed-icon",
            }
            : [],
        });
      } else {
        if (
          nextProps.login_data.data.message[0] ===
          "User not exist in the system!"
        ) {
          this.setState({ isLoading: false });
          Swal.close();
          window.location.href = "/registerSocialMedia";
        } else {
          this.setState({ isLoading: false });
          Swal.fire({
            iconHtml: !isDesktop
              ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
              : "",
            title: t("global.failed"),
            text: nextProps.login_data.data.message[0],
            // icon: nextProps.login_data.data.msgType,
            icon: "error",
            confirmButtonText: t("global.ok"),
            customClass: !isDesktop
              ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
              }
              : [],
          });
        }
      }
    }

    if (
      nextProps.social_media_user_verification_result !==
      this.props.social_media_user_verification_result
    ) {
      if (
        nextProps.social_media_user_verification_result.responseCode === 200
      ) {
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">'
            : "",
          icon: "success",
          title: t("global.success"),
          html: t("profile.linked-success"),
          showConfirmButton: true,
          timer: 2000,
          customClass: !isDesktop
            ? {
              container: "swal-mobile-container sucess",
              icon: "swal-success-icon",
            }
            : [],
        }).then((result) => {
          // if(result.isConfirmed){
          window.location.href = "/personal-information";
          // }
        });
      } else if (
        nextProps.social_media_user_verification_result.responseCode === 422
      ) {
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
            : "",
          title: t("global.failed"),
          text: nextProps.social_media_user_verification_result.message,
          icon: nextProps.social_media_user_verification_result.msgType,
          confirmButtonText: t("global.ok"),
          customClass: !isDesktop
            ? {
              container: "swal-mobile-container failed",
              icon: "swal-failed-icon",
            }
            : [],
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/personal-information";
          }
        });
      }
    }

    if (nextProps.sabaOdds !== this.props.sabaOdds && nextProps.sabaOdds !== undefined) {
      if (nextProps.sabaOdds.responseCode === 200 && nextProps.sabaOdds.msgType === 'success') {
        // console.log(nextProps.sabaOdds.data.odds)
        this.setState({
          sabaOdds: nextProps.sabaOdds.data.odds,
        });
      }
    }

    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  clickPromotedEventsHandler = (video_id) => {
    this.setState({
      promotedVideoId: video_id,
    });
  };

  refCodeCheckingAndMobileRedirect = () => {
    let referralCode = new URLSearchParams(document.location.search).get(
      "refCode"
    );
    if (referralCode !== null) {
      sessionStorage.setItem("referralCode", referralCode);
      let viewData = {};
      viewData.referralCode = referralCode;
      this.props.updateViewCount(viewData);
    }

    if (!isDesktop) {
      // handle mobile redirect to the following page
      return <Redirect to={`/online-live-games-${dynamicCountry}`} />;
    }
  };

  // checkShowBackupLink = () => {
  //     if(localStorage.getItem("showBackupUrl") === true){
  //         this.setState({
  //             showBackupUrlModal:true
  //         })
  //     }
  // }

  toggleBackupUrlModal = () => {
    this.setState({
      showBackupUrlModal: !this.state.showBackupUrlModal,
    });
  };

  toggleBonanzaModal = () => {
    this.setState({
      showBonanzaModal: !this.state.showBonanzaModal
    });
  }

  setDontShow = (e) => {
    const data = {};
    data.checked = e.target.checked;
    this.props.updateMemberPopUp(data);
  };

  setDontShowBonanza = (e) => {
    this.setState({
      showBonanzaModal: !this.state.showBonanzaModal
    }, () => {
      localStorage.setItem("noBonanza", true);
    });
  }

  redirectBackupUrlHandler = (e, value) => {
    e.preventDefault();
    window.location.href = value;
  };

  openEventsBonus = () => {
    this.setState({
      bonusStatus: true,
    });
  };

  toggleHomeEvents = () => {
    this.setState({
      homePageEvents: false,
    });

    localStorage.setItem("showAngbao", false);
    document.body.classList.remove('modal-open');
  };

  accessGame = (game) => {
    // console.log(game);
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        showCancelButton: (!isDesktop) ? false : '',
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    } else {
      localStorage.setItem("gameLauncher", 'sport|' + game);
      window.open('/game-launcher');
    }
  };

  encryptionAuth = (data) => {
    data.trxTime = moment().unix();
    let toHashStr = "";
    let hashStr = "";
    let paramsKey = [];
    for (const key in data) {
      if (!paramsKey.includes(key)) {
        paramsKey.push(key);
      }
    }

    paramsKey.sort();
    paramsKey.forEach((val) => {
        let x = data[val];
        if (x !== "") {
            if (val === "file") {
                // file required hashing params
                toHashStr += val + x.name + x.size;
            } else {
                toHashStr += val + x;
            }
        }
    });
    toHashStr += process.env.REACT_APP_HASH_SECRET;
    hashStr = CryptoJS.MD5(toHashStr).toString();
    data.trxHash = hashStr;

    return data;
  };


  render() {
    const homeImg = this.imageImport(
      require.context(
        "../../assets/images/home/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    // const videos = this.imageImport(
    //   require.context(
    //     "../../assets/images/videos/",
    //     false,
    //     /\.(png|jpe?g|svg|mp4)$/
    //   )
    // );
    const christmas = this.imageImport(
      require.context(
        "../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

        const mooncake = this.imageImport(
      require.context(
        "../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const chinese = this.imageImport(
      require.context(
        "../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let topProfileData = null;
    let backupUrlLoading = true;
    let showBackUpUrl = false;
    let isLogin = localStorage.getItem('accessToken') ? true : false;

    if (this.props.topProfileData) {
      backupUrlLoading = false;
      topProfileData = this.props.topProfileData && this.props.topProfileData.data;
      // showBackUpUrl = topProfileData.topProfile.show_pop_up_url === true; // temporary turn off for r77
    }

    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    return (
      <Auxiliary>
        {isDesktop ? (
          <>
            {this.state.currentEvent === "christmas" &&               
              <img className="master-bg" src={christmas["master-bg.jpg"]} alt="background"/>
            }

            {this.state.currentEvent === "cny" &&              
              <>
                <img className="events-side-deco left" src={chinese["cny-flower-1.webp"]} alt="r77 cny flower"/>  
                <img className="events-side-deco right" src={chinese["cny-flower-2.webp"]} alt="r77 cny flower"/>  
              </>
            }

            {this.state.currentEvent === "" &&               
              <img className="master-bg" src={homeImg["master-bg.webp"]} alt="background"/>
            }
 
            {/* {
                            this.state.currentEvent === "christmas" &&
                            <Snowfall
                                radius={[0.5, 5.0]}
                                style={{
                                    position: 'fixed',
                                    width: '100vw',
                                    height: '100vh',
                                    zIndex: 1
                                }}
                            />
                        } */}
            { 
              localStorage.getItem("accessToken") &&
              !localStorage.getItem("showAngbao") &&
              this.state.showAngpau === true &&
              this.state.currentEvent === "cny" &&(
                <HomeAngpaoEvent
                  {...this.state}
                  {...this.props}
                  // bonusStatus={this.state.bonusStatus}
                  // homePageEvents={this.state.homePageEvents}
                  openEventsBonus={this.openEventsBonus}
                  toggleHomeEvents={this.toggleHomeEvents}
                />
              )}
              {/* {localStorage.getItem("accessToken") &&
              this.state.showAngpau === true &&
              !localStorage.getItem("showAngbao") &&
              this.state.currentEvent === "raya" && (
                  <RayaEvents
                    {...this.state}
                    {...this.props}
                    bonusStatus={this.state.bonusStatus}
                    homePageEvents={this.state.homePageEvents}
                    openEventsBonus={this.openEventsBonus}
                    toggleHomeEvents={this.toggleHomeEvents}
                  />
              )
                  }
                  {this.state.showAngpau === true &&
                  localStorage.getItem("accessToken") &&
                  !localStorage.getItem("showAngbao") &&
                  this.state.currentEvent === "dewali" && (
                    <DewaliEvent 
                    {...this.state}
                    {...this.props}
                    bonusStatus={this.state.bonusStatus}
                    homePageEvents={this.state.homePageEvents}
                    openEventsBonus={this.openEventsBonus}
                    toggleHomeEvents={this.toggleHomeEvents}
                    />
                  )
                  } */}
                  { this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
                  <div className="event-top-deco">
                  <img className="top-deco-one" src={mooncake["content-deco-2.webp"]} alt="deco" />
                  <img className="top-deco-two" src={mooncake["content-deco-2.webp"]} alt="deco" />
                </div> :
                    ("")}
            <Banner category="home" />
            {/* <section className="event-countdown-section">
                            <div className="x-container">
                                <div className="row">
                                    <div className="col-6">
                                        <Link className="card-shadow" to={{ pathname: '/fifa-world-cup' }}>
                                            <img src={homeImg["fifa1-bg.jpg"]} alt="fifa" />
                                        </Link>
                                        <CountDown timeEnd={"2022-11-20"} sec={false} background="white" width={50} height={70} />
                                    </div>
                                    <div className="col-6">
                                        <div className="card-shadow">
                                            <img src={homeImg[`fifa2-bg${imageLanguage}.jpg`]} alt="fifa" />
                                        </div>
                                        <Link className="enter-btn-blue" to={{ pathname: (localStorage.getItem("accessToken")) ? "/fifa-qna" : "/login" }}>
                                            {t("global.lets-play")} !
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section> */}

            {/* <LiveStreaming/> */}
            {this.state.currentEvent === "merdeka" && this.props.currentUser.country_id !== 4 ?
              <video className="merdeka-video" autoPlay loop muted>
              <source src={merdekaVideo} type="video/mp4" /> 
            </video>
            :
            this.state.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ?
            <video className="merdeka-video" autoPlay loop muted>
            <source src={mooncakeVideo} type="video/mp4" />
            </video>
            :
            this.state.currentEvent === "halloween" ? (
              <video className="merdeka-video" autoPlay loop muted>
              <source src={halloweenVideo} type="video/mp4" />
              </video>
            ) :
            ("")
              }

            <div
              className={`${this.state.currentEvent === "raya" && "raya-bg-deco"
                }`}
            >
              {/* {
                process.env.REACT_APP_ENV === 'staging' && (
                  <> */}
              <EventSectionNew
                currentEvent={this.state.currentEvent}
                {...this.state}
                accessGame={this.accessGame}
              />
              <ProductOverview currentEvent={this.state.currentEvent} />
              {/* </>
                )
              } */}

              {/* <EventSection
                currentEvent={this.state.currentEvent}
                loading={this.state.loading}
                data={this.state.data}
                promotedVideoId={this.state.promotedVideoId}
                clickPromotedEventsHandler={this.clickPromotedEventsHandler}
              /> */}
              <MainSection currentEvent={this.state.currentEvent} />
            </div>
            {/* {((!isLogin && this.state.showBonanza) || (isLogin && showBackUpUrl)) && (            
              <BackupUrlModal
                {...this.state}
                {...this.props}
                backupUrlLoading={backupUrlLoading}
                topProfileData={topProfileData}
                setDontShow={this.setDontShow}
                setDontShowBonanza={this.setDontShowBonanza}
                toggleBackupUrlModal={this.toggleBackupUrlModal}
                toggleBonanzaModal={this.toggleBonanzaModal}
                handleSubmit={this.handleSubmit}
                redirectBackupUrlHandler={this.redirectBackupUrlHandler}
              />
            )} */}
          </>
        ) : this.props.location.hash ? (
          <></>
        ) : (
          this.refCodeCheckingAndMobileRedirect()
        )}
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
    promoted_events_data: state.promotedEvents.promoted_events_data,
    login_data: state.auth.login_data,
    social_media_user_verification_result:
      state.profile.social_media_user_verification_result,
    topProfileData: state.profile.top_profile_data,
    claim_angpau_result: state.events.claim_angpau_result,
    sabaOdds: state.home.odds,
    update_viewcount: state.referral.update_viewcount
  };
};

const mapDispatchToProps = {
  getUserData,
  getPromotedEvents,
  postLogin,
  socialMediaUserVerification,
  getTopProfile,
  updateMemberPopUp,
  claimAngpau,
  getSabaOdds,
  updateViewCount
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(Home));
