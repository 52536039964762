import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import BankingDetails from "./BankingDetails";
import CryptoDetails from "./CryptoDetails";
import { Icon } from "@iconify/react";

class MobileWallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "banking_details",
        };
    }

    changePageShow = (name) => {
        this.setState({
            pageShow: name,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const icons = this.imageImport(require.context("../../../assets/images/profile/nav-icons", false, /\.(png|jpe?g|svg|webp)$/));
        const mbIcon = this.imageImport(require.context("../../../assets/images/profile/mobile/icons", false, /\.(png|jpe?g|svg)$/));

        let topProfileData = null;
        if (!this.props.loading) {
            topProfileData = this.props.topProfileData;
        }

        return (
            <section className="pf-m-dashboard ">
                <MobileSubHeader
                    // className="bg-white"
                    returnPage="/me"
                    header={t("profile-sidebar.my-wallet")}
                    subPage="/transaction-history"
                    subPageHeader={t("profile-sidebar.history")}
                />
                <div className="my-m-col">
                    <div className="mw-m-balance">
                        <p>
                            {t("top-profile.main-wallet")} ({this.props.currentUser.currency})
                        </p>
                        <p className="number mb-2">
                            {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                (topProfileData && topProfileData.topProfile && topProfileData.topProfile.balance) || 0.0
                            )}{" "}
                        </p>

                        <div className="interest-tnc mx-auto" onClick={this.props.toggleInterestModal}>
                            <Icon icon="material-symbols:info" />
                            <span>
                                {t("global.including")}{" "}
                                {Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(this.props.interestPercentage || 0.0)}%{" "}
                                {t("global.interest")}
                            </span>
                        </div>

                        {/* <div className="interest-container">
                            <p>{t("top-profile.total-interest")}</p>
                            <p className="number">
                                {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.props.totalInterestPayout || 0.0)}{" "}
                                <span>({this.props.currentUser.currency})</span>
                            </p>
                        </div> */}
                    </div>

                    <div className="coin-earning"> 
                        <div className="coin">
                            <img src={mbIcon["r77-coin.svg"]} alt="coin" />
                            <div>
                                <p className="number-label">{t("top-profile.r77-coin")}</p>
                                <p className="number">
                                    {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                        (topProfileData != null && topProfileData.topProfile.coin) || 0
                                    )}
                                </p>
                                <span className="my-learn-more" onClick={this.props.toggleCoinModal}>
                                    {t("global.learn-more")}
                                </span>
                            </div>
                        </div>
                        <div className="earning">
                            <p className="number-label">{t("profile-sidebar.today-earning")}</p>
                            <p className="number">
                                +{" "}
                                {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                    (topProfileData != null && topProfileData.topProfile.today_coin) || 0
                                )}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="my-m-btm-col mt-3">
                    <NavLink className="my-m-btm-link" to={{ pathname: "/deposit" }}>
                        <img src={icons["deposit-ico.webp"]} alt="deposit-ico" />
                        <p>{t("profile-sidebar.deposit")}</p>
                    </NavLink>
                    <NavLink className="my-m-btm-link" to={{ pathname: "/withdrawal" }}>
                        <img src={icons["withdrawal-ico.webp"]} alt="withdrawal-ico" />
                        <p>{t("profile-sidebar.withdraw")}</p>
                    </NavLink>
                </div>

                <div className="pd-m-card-col">
                    <div className="rh-m-pd-card">
                    <h6>{t("profile-sidebar.account-details")}</h6>
                    <div className="fd-col">
                        <ul className="nav nav-pills" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="banking_details"
                                    data-bs-toggle="tab"
                                    data-bs-target="#banking-details"
                                    type="button"
                                    role="tab"
                                    aria-controls="banking-details"
                                    aria-selected="true"
                                    onClick={() => this.changePageShow("banking_details")}
                                >
                                    {t("account-details.banking-details")}
                                </button>
                            </li>

                            <li className="nav-item" role="presentation">
                                <button
                                    className="nav-link"
                                    id="crypto_details"
                                    data-bs-toggle="tab"
                                    data-bs-target="#crypto-details"
                                    type="button"
                                    role="tab"
                                    aria-controls="crypto-details"
                                    aria-selected="true"
                                    onClick={() => this.changePageShow("crypto_details")}
                                >
                                    {t("account-details.crypto-details")}
                                </button>
                            </li>
                        </ul>
                        {this.state.pageShow === "banking_details" && <BankingDetails />}
                        {this.state.pageShow === "crypto_details" && <CryptoDetails />}
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default withNamespaces("translation")(MobileWallet);
