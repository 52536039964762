import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { isDesktop } from "react-device-detect";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Accordion } from 'react-bootstrap'
// import MobileSelectRadio from '../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio';
import Modal from "react-modal";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import domain from "../../common/data/domain.json";

let dynamicCountry = "malaysia";
if(domain.id.key.includes(window.location.host)){
	dynamicCountry = "indonesia";
}

export class MallModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			favLove: false,
			valueCount: 1,
			subtotalAmount: this.props.showProduct?.coin * 1,
			totalLeftAmount: "",
			invalid: "",

			showCasinoSelectRadio: false,
			moreOptions: [],
			activeKey: "",
		};
	}

	componentDidMount() {
		// const { t } = this.props;
		const moreOptions = [
			{ icon: "sports-icon", label: "Sports", url: `online-sports-games-${dynamicCountry}` },
			{ icon: "lc-icon", label: "Live Casino", url: `online-live-games-${dynamicCountry}` },
			{ icon: "slot-icon", label: "Slots", url: "slot/pragmatic-play" },
			{ icon: "game-icon", label: "Games", url: "games/playtech" },
			{ icon: "p2p-icon", label: "P2P", url: "p2p/gameplay-interactive" },
			{ icon: "lottery-icon", label: "Lottery", url: "lottery/gameplay-interactive" },
		];

		this.setState({
			moreOptions: moreOptions
		});
	}

	toggleCasinoSelectRadio = () => {
		this.setState({
			showCasinoSelectRadio: !this.state.showCasinoSelectRadio,
		});
	};

	handleCasinoOptionChange = (event) => {
		window.location.href = `${window.location.hostname === "localhost" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname}/${event.url}`
	}

	imageImport = (r) => {
		let images = {};
		r.keys().map((item, index) => {
			return (images[item.replace("./", "")] = r(item)["default"]);
		});
		return images;
	};

	render() {
		const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));
		const icon = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));
		const { t } = this.props;
		let item = this.props.showProduct || false;
		let dynamicUrl = `/online-sports-games-${dynamicCountry}`;
		return (
			<Auxiliary>
				{item && (
				<Modal
					isOpen={this.props.productModal}
					closeTimeoutMS={500}
					portalClassName={`x33mall global-modal`}
					className="modal modal-dialog modal-dialog-centered"
					ariaHideApp={false}
					onRequestClose={this.props.toggleProductModal}>
					{
						isDesktop ? (
							<>
								{/* {item && ( */}
									<div className="modal-content">
										<div className="modal-header">
											<h5 className="modal-title"><Icon type="button" icon="mynaui:cart" />{t("mall.order-summary")}</h5>
											<Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleProductModal} />
										</div>
										<div className="px-3 pb-3">
											<div className="modal-body">
												<div className="modal-body-cont">
													<h6>{t("mall.your-order")}</h6>
													<div className="product-broder">
													<div className="ratio ratio-4x3">
														<img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} className="product-img" />
														</div>
													</div>
												</div>
												<div className="modal-body-cont border-left">
														<span className='details-cont'>
															<h6>{item.title}</h6>
																<button type="button" className="fav-btn" onClick={() => this.props.addFav(item.id)}>
																	{this.props.favorites.length && this.props.favorites.includes(item.id) ? (
																		<img src={img["love-active.svg"]} alt="fav active"/>
																	) : (
																		<img src={img["love.svg"]} alt="fav"/>
																	)}
															</button>
													</span>
													<div className='my-3'>
														<button className="btn count-btn left" onClick={this.props.minusProductCount}>-</button><button className="btn count-btn center" type="text">{this.props.qty}</button><button className="btn count-btn right" onClick={this.props.addProductCount}>+</button>
													</div>
													<span className="details-cont">
														<small>{t("mall.balance", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</small><small className="f-red"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((this.props.userCoin))}</small>
													</span>
													<span className="details-cont">
														<small>
														{t("mall.amount")}
															<br />
															{t("mall.subtotal")}
														</small>
														<small>
															<img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.coin}
														</small>
													</span>
													<hr />
													<span className="details-cont">
														<h6>{t("redemption-history.order-total")}</h6><h6 className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{this.props.total}</h6>
													</span>
													{/* <span className="feedback" >
														<small > You still need to earn <img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />8000 more</small>
													</span> */}
													<button type="button" className="btn btn-gradient-blue" onClick={() => this.props.redeem(item.id, this.props.qty)}>{t("mall.add-to-cart")}</button>
													{/* <button type="button" className="btn btn-gradient-blue" onClick={this.props.toggleRedeemModal} >Earn More R77 Coins</button> */}
													<button type="button" className="btn  btn-outline-blue" onClick={this.props.toggleProductModal}>{t("mall.cancel")}</button>
												</div>
											</div>
										</div>
										<div className="product-tnc">
											<p>{t("mall.tnc-1")}</p>
											<p>{t("mall.tnc-2")}</p>
											<p>{t("mall.tnc-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
										</div>
									</div>
								{/* )} */}
							</>
						) : (
							<div className="modal-content mobile-modal-bg-white ">
								<div className="modal-header">
									<h5 className="modal-title"><Icon type="button" icon="mynaui:cart" />{t("mall.order-summary")}</h5>
									<button type="button" className="fav-btn" onClick={() => this.props.addFav(item.id)}>
											{this.props.favorites.length && this.props.favorites.includes(item.id) ? (
												<img src={img["love-active.svg"]} alt="fav active"/>
											) : (
												<img src={img["love.svg"]} alt="fav"/>
											)}
									</button>
								</div>
								<div className="modal-body">
									<div className="modal-body-cont">
										<h6 className='mb-2'>{t("mall.your-order")}</h6>
										<div className="row align-items-center">
											<div className="col-5">
												<div className="product-broder">
												<div className="ratio ratio-1x1">
													<img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} className="product-img" />
													</div>
												</div>
											</div>
											<div className="col-7">
												<h6 className="details-title">{item.title}</h6>
												<div className="d-flex justify-content-between align-items-center">
													<div>
														<button className="btn count-btn left" onClick={this.props.minusProductCount}>-</button><button className="btn count-btn center" type="text">{this.props.qty}</button><button className="btn count-btn right" onClick={this.props.addProductCount}>+</button>
													</div>
												</div>
											</div>
										</div>
										<hr />
										<span className="details-cont">
											<small>{t("mall.balance", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</small><small className="f-red"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((this.props.userCoin))}</small>
										</span>
										<span className="details-cont">
											<small>{t("mall.amount")}<br /> {t("mall.subtotal")} </small><small ><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.coin}</small>
										</span>
										<hr />
										<span className="details-cont">
											<h6>{t("redemption-history.order-total")}</h6><h6 className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{this.props.total}</h6>
										</span>
										{/* <span className="feedback" >
											<small >{t("mall.you-still")} <img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />8000 {t("profile.more")} </small>
										</span> */}
									</div>
								</div>
								<div className="product-tnc">
											<p>{t("mall.tnc-1")}</p>
											<p>{t("mall.tnc-2")}</p>
											<p>{t("mall.tnc-3", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</p>
										</div>
								<button type="button" className="btn btn-outline-blue" onClick={() => this.props.redeem(item.id, this.props.qty)}>
									{t("mall.add-to-cart")}
								</button>
								{/* <button type="button" className={`btn btn-white ${this.props.filterShow ? "active" : " "}`} onClick={this.props.toggleRedeemModal} >{t("mall.play-to-earn")} </button>
								<MobileSelectRadio
									name="PleaseSelect"
									showSelectRadio={this.props.showCasinoSelectRadio}
									toggleHandler={this.toggleCasinoSelectRadio}
									onChange={(event) => this.handleCasinoOptionChange(event)}
									options={this.props.moreOptions}
								/> */}
								<button type="button" className="btn btn-white" onClick={this.props.toggleProductModal}>{t("mall.cancel")}</button>
							</div>
						)
					}
				</Modal>
				)}

				{/* //failed modal */}
				<Modal
					isOpen={this.props.redeemModal}
					closeTimeoutMS={500}
					portalClassName={`select-page-modal global-modal`}
					className="modal modal-dialog modal-dialog-centered"
					ariaHideApp={false}
					onRequestClose={this.props.toggleRedeemModal}>

					<div className="modal-content">
						<form onSubmit={this.handleSubmit}>
							<h5 className="modal-title">{t("mall.earn-more")}</h5>
							<div className="modal-body">
								<ul>
									<li className={`btn btn-outline-blue ${this.state.url === dynamicUrl && 'active'}`} onClick={() => this.setState({ url: dynamicUrl })}>
										<img src={icon["sports-icon.png"]} alt="sports" className="game-icon" /> {t("page-header.sports")}
									</li>
									<li className={`btn btn-outline-blue ${this.state.url === "/lc" && 'active'}`} onClick={() => this.setState({ url: "/lc" })}>
										<img src={icon["lc-icon.png"]} alt="live casino" className="game-icon" /> {t("page-header.live-casino")}
									</li>
									<li className={`btn btn-outline-blue ${this.state.url === "slots" && 'active'}`} onClick={() => this.setState({ url: "slots" })}>
										<img src={icon["slot-icon.png"]} alt="slots" className="game-icon" /> {t("page-header.slots")}
									</li>
									<li className={`btn btn-outline-blue ${this.state.url === "games" && 'active'}`} onClick={() => this.setState({ url: "games" })}>
										<img src={icon["game-icon.png"]} alt="games" className="game-icon" />  {t("page-header.games")}
									</li>
									<li className={`btn btn-outline-blue ${this.state.url === "p2p" && 'active'}`} onClick={() => this.setState({ url: "p2p" })}>
										<img src={icon["p2p-icon.png"]} alt="P2P" className="game-icon" /> {t("page-header.p2p")}
									</li>
									<li className={`btn btn-outline-blue ${this.state.url === "lottery" && 'active'}`} onClick={() => this.setState({ url: "lottery" })}>
										<img src={icon["lottery-icon.png"]} alt="Lottery" className="game-icon" /> {t("page-header.lottery")}
									</li>
								</ul>
								<NavLink to={{ pathname: `${this.state.url}` }} className="nav-link btn-gradient-blue" activeClassName='active'>
									Play Now
								</NavLink>
								<button type="button" className="btn btn-outline-blue back-btn" onClick={this.props.toggleRedeemModal }>{t("datatables.back")}</button>
							</div>
						</form>
					</div>
				</Modal>
			</Auxiliary >
		)
	}
}

export default (withNamespaces("translation")(MallModal));