import React, { Component } from 'react'
import { default as ReactSelect } from "react-select";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MallModal from "./MallModal";
// import moment from "moment";
import { formValidation } from '../../hoc/Shared/utility';
import { getMemberProfile, updateMemberProfile, getTopProfile } from "../../store/ducks/profile/actions";
// import NoRecords from '../../../components/NoRecords/NoRecords';
import { isDesktop } from "react-device-detect";
import MobileMallCheckout from './MobileMallCheckout';
import AddAddressModal from '../../components/AddressModal/AddAddressModal';
import { withNamespaces } from "react-i18next";
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { checkout } from "../../store/ducks/X33Mall/actions";

const initialFormInputState = {
  name: "",
  contact_no: "",
  contact_no_value: "",
  contact_no_prefix: "60",
  pwdStrength: "low",
  address: [],
  email: "",
};

let contactNoArry = [];
// Check country to limit the contact no fields
// let contact_no_min_limits = 9;
// let contact_no_max_limits = 10;
let contact_no_prefix = [{ value: "60", name: "Malaysia", image: "MY.webp", phone: "+60", code: "MY" }];

export class MallCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initiated: false,
      formFilter: initialFormInputState,
      products: [],
      totalItem: 0,
      totalCoin: 0,
      addressModal: false,
      topProfileData: [],
      emailRequire: false,
    };

    this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
  }

  componentDidMount() {
    let totalItem = 0;
    let totalCoin = 0;
    let emailRequire = false;
    let checkout_items = JSON.parse(localStorage.getItem('checkout_items'));

    if (checkout_items === null) {
      return window.location.href = '/mall/cart';
    }

    checkout_items.map((item, idx) => {
        if(item.category_slug === 'voucher') {
          emailRequire = true;
        }
        totalItem += item.qty;
        totalCoin += (item.qty * item.coin);
        return null;
    });

    this.setState({
      products: checkout_items,
      emailRequire,
      totalItem: totalItem,
      totalCoin: totalCoin
    });

    formValidation("basic_info_from");

    if (this.props.topProfileData === undefined) {
      if (localStorage.getItem("accessToken")) {
        this.props.getTopProfile();
      }
    }
    else {
      this.setState({ loading: false });
    }

    this.props.getMemberProfile();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.memberProfileData !== this.props.memberProfileData && nextProps.memberProfileData !== undefined) {
      if (nextProps.memberProfileData.responseCode === 200) {
          let memberProfile = nextProps.memberProfileData.data.memberProfile[0];
          // var dateOfBirth = null;
          // if (memberProfile.dateOfBirth) {
          //     dateOfBirth = moment(memberProfile.dateOfBirth).toDate();
          // }

          let newFilter = Object.assign({}, this.state.formFilter);

          newFilter["address"] = memberProfile.address;
          // newFilter["contact_no_prefix"] = memberProfile.address?.contact_no?.substring(-2, 2) || '';

          if (memberProfile.address?.name !== null && !this.state.initiated) {
            newFilter["name"] = memberProfile.address?.name;
          }

          if (memberProfile.address?.contact_no !== null && !this.state.initiated) {
            newFilter["contact_no_value"] = memberProfile.address?.contact_no?.substring(2);
          }

          if (memberProfile.email !== null && !this.state.initiated) {
            newFilter["email"] = memberProfile.email;
          }
          
          this.setState({
              formFilter: newFilter,
              initiated: true
          });
      }
    }

    if (nextProps.checkout_data !== this.props.checkout_data && nextProps.checkout_data !== undefined) {
      if (nextProps.checkout_data.data.responseCode === 200) {
        localStorage.removeItem('checkout_items');

        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
          icon: 'success',
          title: t('global.success'),
          html: t('global.success'),
          confirmButtonText: t('global.ok'),
          customClass: (!isDesktop) ? {
              container: 'swal-mobile-container sucess',
              icon: 'swal-success-icon'
          }: [],
        }).then((result) => {
          if (result.isConfirmed) {
            // return window.location.href = '/redemption-history';
            this.props.history.push({
              pathname: "/redemption-history",
              state: { 
                historyTab: 'mall',
                pageShow: 'mall_details'
              }
            });
          }
        });
      }
    }

    return true;
  }

  handleCountryContactNo = (event) => {
    // switch (event.value) {
    //   case "+60":
    //     contact_no_min_limits = 9;
    //     contact_no_max_limits = 10;
    //     break;
    //   case "+66":
    //     contact_no_min_limits = 8;
    //     contact_no_max_limits = 8;
    //     break;
    //   case "+62":
    //     contact_no_min_limits = 10;
    //     contact_no_max_limits = 10;
    //     break;
    //   case "+84":
    //     contact_no_min_limits = 9;
    //     contact_no_max_limits = 9;
    //     break;
    //   case "+1":
    //     contact_no_min_limits = 10;
    //     contact_no_max_limits = 10;
    //     break;
    //   default:
    //     break;
    // }

    let newFilter = Object.assign({}, this.state.formFilter);
    newFilter['contact_no_value'] = event.value;

    this.setState({
      formFilter: newFilter,
      formError: false,
      errorMsg: []
    });
  }

  toggleAddressModal = () => {
    this.setState({
      addressModal: !this.state.addressModal,
    })
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  onInputChangeHandler = (event) => {
    // console.log(event.target.name+' | '+event.target.value);

    let newFilter = Object.assign({}, this.state.formFilter);

    if (event.target.name === "name" || event.target.name === "email") {
        newFilter[event.target.name] = event.target.value;
    }

    if (event.target.name === "contact_no_value") {
        newFilter["contact_no_value"] = event.target.value;
    }

    this.setState({
        formFilter: newFilter,
    });
  }

  checkoutHandler = (e) => {
    const { t } = this.props;
    e.preventDefault();

    if (this.state.formFilter.name === '' || this.state.formFilter.contact_no_value === '' || !this.state.formFilter.address || !this.state.products.length || (this.state.emailRequire && this.state.formFilter.email === '')) {
      Swal.fire({
        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
        icon: "error",
        title: t("global.sorry"),
        html: t("mall.all-fields-are-required"),
        showConfirmButton: true,
        customClass: !isDesktop
          ? {
              container: "swal-mobile-container sorry",
              icon: "swal-sorry-icon",
            }
          : [],
      }).then((result) => {
      });

      return false;
    }

    Swal.fire({
      iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
      title: t("global.confirmation"),
      text: t("global.would-you-like-to-proceed") + "?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("global.save"),
      cancelButtonText: t("global.cancel"),
      customClass: (!isDesktop) ? {
          container: "swal-mobile-container confirmation",
          icon: "swal-confirmation-icon",
      } : [],
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        this.props.checkout({
          address: this.state.formFilter,
          cart: this.state.products,
          lang: this.props.currentUser.language_id
        });
      }
    });
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));
    const flag = this.imageImport(require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));

    return (
      <Auxiliary>
        {
          !this.state.formFilter.loading &&
          <AddAddressModal
            toggleAddressModal={this.toggleAddressModal}
            {...this.state}
            {...this.props}
          />
        }
        {
          isDesktop ? (
            <>
              <section className="x33mall checkout">
                <div className="pf-dashboard">
                  <div className="x-container">
                    <div className="pf-fade-col">
                      <div className="pd-card-col">
                        <h4>{t("mall.checkout")}</h4>
                        <table className="table pd-table w-border mb-0">
                          <thead>
                            <tr>
                              <th scope="col">{t("redemption-history.order-total")}</th>
                              <th scope="col">{t("mall.items")}</th>
                              <th scope="col">{t("mall.quantity")}</th>
                              <th scope="col">{t("mall.subtotal")}</th>
                            </tr>
                          </thead>
                          {this.state.products && this.state.products.map((item, index) => (
                            <tbody key={index}>
                              <tr>
                                <td><div className='ratio ratio-16x9'><img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} /></div></td>
                                <td>{item.title}</td>
                                <td>{item.qty}</td>
                                <td><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.qty * item.coin}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                        <div className="ck-title">
                          <h6>{t("mall.deli-info")}</h6>
                        </div>
                        <div className="pd-content ps-3">
                          <form className="forms-col" onSubmit={this.checkoutHandler}>
                            {
                              !this.state.initiated ? (
                                <Skeleton count={4} height={'2.5rem'} style={{marginBottom: "1rem"}}/>
                              ) : (
                                <Fade duration={1000}>
                                  <div className="row">
                                    <label className="col-3 col-form-label">{t("profile.fullname")}</label>
                                    <div className="col-9 align-self-center">
                                      <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.formFilter.name}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <label className="col-3 col-form-label">{t("profile.contact-number")}</label>
                                    <div className="col-9 align-self-center">

                                      <div className="d-flex justify-content-between input-group left">
                                        <div className="select-phone-ck">
                                          <ReactSelect
                                            classNamePrefix="react-select"
                                            defaultValue={contact_no_prefix.filter((country) => country.code === "MY")}
                                            options={contactNoArry}
                                            onChange={this.handleCountryContactNo}
                                            formatOptionLabel={(item) => (
                                              <>
                                                <img src={flag[`${item.image}`]} alt={item.image} />
                                                <p>{item.value}</p>
                                                <p className='mx-1'>{item.name}</p>
                                              </>
                                            )}
                                          />
                                        </div>
                                        <input
                                          type="number"
                                          name="contact_no_value"
                                          className="form-control"
                                          value={this.state.formFilter.contact_no_value}
                                          onChange={(event) => this.onInputChangeHandler(event)}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {(this.state.emailRequire) &&
                                    <>
                                      <label className="col-3 col-form-label">{t("profile.email")}</label>
                                      <div className="col-9 align-self-center">
                                        <input
                                          name="email"
                                          type="text"
                                          className="form-control"
                                          value={this.state.formFilter.email}
                                          onChange={(event) => this.onInputChangeHandler(event)}
                                        />
                                      </div>
                                    </>}
                                  </div>
                                  <div className="row">
                                    <label className="col-3 col-form-label">{t("profile.address")}: </label>
                                    <div className="col-9 align-self-center">
                                      {(!this.state.formFilter.loading)
                                        ?
                                        <div className="d-flex justify-content-between">
                                          {(this.state.formFilter.address && this.state.formFilter.address.full_address)
                                            ?
                                            <div className="verified-col">
                                              <p>
                                                {this.state.formFilter.address.full_address}
                                              </p>
                                            </div>
                                            :
                                            <>
                                              <div className="verified-col">
                                                <p> - </p>
                                              </div>
                                            </>
                                          }
                                          <div>
                                            <button
                                              className="btn btn-outline-blue"
                                              type="button"
                                              onClick={this.toggleAddressModal}
                                            >
                                              {this.state.formFilter.address && this.state.formFilter.address.full_address ? t("global.edit") : t("crypto-address-details.add-withdrawal-address")}

                                            </button>
                                          </div>
                                        </div>
                                        :
                                        <Skeleton count={1} width={200} />
                                      }
                                    </div>
                                  </div>
                                  {/* <div className="row">
                                    <label className="col-3 col-form-label">Shipping Option:</label>
                                    <div className="col-9 align-self-center">
                                      <p>Standard Delivery (Receive by 5-11 Mar)</p>
                                    </div>
                                  </div> */}
                                  {/* <div className="row">
                                    <label className="col-3 col-form-label">Message :</label>
                                    <div className="col-9 align-self-center">
                                      <textarea className="form-control" rows="5"></textarea>
                                    </div>
                                  </div> */}
                                </Fade>
                              )
                            }
                            <hr />
                            <div className='text-end'>
                              <p className='fw-bold mb-3'>{t("mall.total")} ({this.state.totalItem} {t("mall.items")}) : {this.state.totalCoin} {t("mall.coins")}</p>

                              <Link className="btn btn-outline-blue submit-btn "
                                to={{ pathname: "/mall/cart" }}>
                                {t("mall.bt-cart")}
                              </Link>
                              <button type="submit" className="btn btn-gradient-blue ms-2 submit-btn">{t("mall.place-order")}</button>
                            </div>
                          </form>
                        </div>
                        {/* <div className="no-records-cont">
                  <NoRecords />
                  <NavLink to={{ pathname: "/x33Mall" }} className="nav-link btn-gradient-blue" activeClassName='active'>
                    Redeem Now
                  </NavLink>
                </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <MallModal
                  {...this.state}
                  {...this.props}
                />
              </section>
            </>
          ) : (
            <MobileMallCheckout
              {...this.state}
              {...this.props}
              handleCountryContactNo={this.handleCountryContactNo}
              onInputChangeHandler={this.onInputChangeHandler}
              checkoutHandler={this.checkoutHandler}
            />
          )
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = (state) => {
  const { profile, x33Mall } = state;

  return {
      currentUser: state.currentUser.data,
      topProfileData: profile.top_profile_data,
      memberProfileData: profile.member_profile_data,
      memberProfileUpdateResult: profile.member_profile_update_result,
      checkout_data: x33Mall.checkout_data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
      getTopProfile: () => dispatch(getTopProfile()),
      getMemberProfile: () => dispatch(getMemberProfile()),
      updateMemberProfile: (data) => dispatch(updateMemberProfile(data)),
      checkout: (data) => dispatch(checkout(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MallCheckout));
