import React, { Component } from 'react';
import { Link } from "react-router-dom";
import InputRange from 'react-input-range';

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import "react-input-range/lib/css/index.css";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import Button from '../../components/Button/Button';
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';
import MobileSelectRadio from '../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio';
import GamePagination from "../../components/Pagination/GamePagination";

export class MobileMall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRadio: false,
      topProfileData: [],
      faqOptions: [],
      options: [],
      // activeOption: '',
      // activeKey: 0,
      filterShow: false
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const faqOptions = [
      { icon: "all", label: t("global.all"), key: 0 },
      // { icon: "star", label: "Latest", key: 1 },
      { icon: "love", label: t("global.favorite"), key: 2 },
      // { icon: "price-high", label: "Price Low to High", key: 3 },
      // { icon: "price-low", label: "Price High to Low", key: 4 },
    ];

    const newOptions = faqOptions.filter((item) => item.key !== this.state.activeKey)

    this.setState({
      options: newOptions
    });
  }

  toggleSelectRadio = () => {
    this.setState({
      showSelectRadio: !this.state.showSelectRadio,
    });
    if (this.state.filterShow === false) {
      this.setState({
        filterShow: true,
      });
    } else if (this.state.filterShow === true) {
      this.setState({
        filterShow: false,
      });
    }
  };

  handleOptionChange = (event) => {
    // this.setState({
    //   activeOption: event.label,
    //   activeKey: event.key,
    // });

    return this.props.filterList(new MouseEvent('click'), event.label.toLowerCase(), true);
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    // const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

    const mbIcon = this.imageImport(
      require.context(
        "../../assets/images/profile/mobile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));
    const favIcon = this.imageImport(require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg)$/));
    const resetIcon = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)	);

    let topProfileData = null;
    if (!this.props.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
    }

    return (
      <Auxiliary>
        <section className="x33mall">
          <div className="x33mall-header">
            <MobileSubHeader
              // className="bg-white"
              returnPage="/me"
              header={t("mall.mall", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}
              subPage='/redemption-history'
              subPageHeader="Records"
            />
            <div className="xmall-m-amount">
              <div className="row align-items-center">
                <div className="col-5ths"><img src={mbIcon["r77-coin.svg"]} alt="coin" /></div>
                <div className="col-9 p-0">
                  <div className="amount">
                    <div className="left">
                      <p>{t("profile-sidebar.coin", { attribute: process.env.REACT_APP_WEBSITE_TITLE }) + (" ")}<span className="number">{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((this.props.userCoin))}</span>
                      </p>
                      <Icon icon="uil:angle-right"/>
                    </div>
                    <div className="right">
                      <Link className="cart-cta" to={{ pathname: "/mall/cart" }}>
                        <Icon type="button" icon="mynaui:cart" />
                        <small className="notify-red-dot">{this.props.cart_count}</small>
                      </Link>
                    </div>
                  </div>
                  <div className="earning">
                    <p>{t("profile-sidebar.today-earning") + (" ")}<span className="number">+ {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((topProfileData != null && topProfileData.topProfile.today_coin) || 0)}</span></p>
                  </div>
                </div>
              </div>
            </div>

            <div className="px-3 mb-4">
              <div className="pf-fade-col">
                <div className="pd-card-col ">
                  <div className="x3m-m-filter">
                    <p>{t("mall.reward")}</p>
                    <button className={`btn btn-m-filter ${this.state.filterShow ? "active" : " "}`} type="button" onClick={this.toggleSelectRadio}>
                      <p>{this.props.filter === 'all' ? t("mall.all") : t("mall.favorite")} <Icon icon={`${this.state.filterShow ? "uil:angle-up" : "uil:angle-down"}`} /></p>
                      <MobileSelectRadio
                        name="PleaseSelect"
                        showSelectRadio={this.state.showSelectRadio}
                        toggleHandler={this.toggleSelectRadio}
                        onChange={this.handleOptionChange}
                        options={this.state.options}
                      />
                    </button>
                  </div>
                  <hr/>
                  <div className='filter-body'>
                    <div className='filter-body-nth-1'>
                      <small>{t("mall.filter", { attribute: process.env.REACT_APP_WEBSITE_TITLE })}</small>
                      <InputRange
                        maxValue={this.props.coinRange.max}
                        minValue={this.props.coinRange.min}
                        value={this.props.rangeInput}
                        onChange={rangeInput => {this.props.setRangeInput(rangeInput)}}
                        onChangeComplete={(rangeInput, type) => this.props.filterList(rangeInput, 'rangeInput')}
                        step="10"
                      />
                    </div>
                    <div className='filter-body-nth-2'>
                      <span className={this.props.filterSequence === 'coinLowtoHigh' ? 'btn-outline-blue' : 'btn-outline-gray'} onClick={(e) => this.props.filterList(e, 'coinLowtoHigh')}><img src={this.props.filterSequence === 'coinLowtoHigh' ?  img["price-high-active.svg"] : img["price-high.svg"]} alt="Price High to Low" /></span>
                      <span className={this.props.filterSequence === 'coinHightoLow' ? 'btn-outline-blue' : 'btn-outline-gray'} onClick={(e) => this.props.filterList(e, 'coinHightoLow')}><img src={this.props.filterSequence === 'coinHightoLow' ?  img["price-low-active.svg"] : img["price-low.svg"]} alt="Price Low to High" /></span>
                      <img className={`${this.props.spin ? "spin" : ""} `} src={resetIcon["rotate-icon.svg"]} alt="reset" onClick={this.props.onResetHandler} />
                    </div>
                  </div>
                  <Fade duration={1000}>
                    <div className="row gx-2">
                      {
                        this.props.loading === true ? (
                          <>
                            {[...Array(6)].map((_, index) => (
                              <div className='col-4' key={index}>
                                <div className="product-body">
                                  <Skeleton count={1} height={420} />
                                </div>
                              </div>
                            ))}
                          </>
                        ) : this.props.products ? (
                          <>
                            {this.props.products && this.props.products.map((item, index) => (
                              <div className="col-4" key={index}>
                                <div className="product-body">
                                  <button type="button" className="fav-btn" onClick={() => this.props.addFav(item.id)}>
                                    {this.props.favorites.length && this.props.favorites.includes(item.id) ? (
                                      <img src={img["love-active.svg"]} alt="fav active"/>
                                    ) : (
                                      <img src={img["love.svg"]} alt="fav"/>
                                    )}
                                  </button>
                                  <div className="product-cont" onClick={(e, item) => this.props.toggleProductModal(e, index)}>
                                    <div className="ratio ratio-4x3">
                                      <img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} />
                                    </div>
                                    <hr/>
                                    <p>{item.title}</p>
                                  </div>
                                  <div className="product-details">
                                    <p className="f-orange">
                                      <img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.coin}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                            <GamePagination
                              totalPage={this.props.totalPage}
                              curPage={this.props.curPage}
                              data={this.props.products}
                              navigateList={(event, type, page)=> this.props.navigateList(event, type, page)}
                            />
                          </>
                        ) : (
                          <>
                            {t('norecord.norecords')}
                          </>
                        )
                      }
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileMall));